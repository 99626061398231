import React, { useState } from 'react';
import dayjs from 'dayjs';
import { colors } from '../../../theme';
import AddIcon from '@mui/icons-material/Add';
import { GridColDef } from '@mui/x-data-grid';
import { TabProps } from '@mui/material/Tab';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import StyledDataGrid from '../../../components/StyledDataGrid';

import Typography from '../../../components/Typography';
import BasicTextfield from '../../../components/Textfield';
import TabsSelectComponent from '../../../components/TabsSelectComponent';
import CollapsibleComponent from '../../../components/CollapsibleComponent';
import ButtonType from '../../../components/Button';
import { BloodLossInfo } from './types/birth.types';
import { customTwMerge } from '../../../utils';

type BloodLossProps = {
  data: BloodLossInfo[] | [];
  disabled: boolean;
  handleNote: (note: string) => void;
  handleSave: (property: string, data: BloodLossInfo[]) => void;
};

const tabsType: TabProps[] = [
  { label: 'QBL', value: 'QBL' },
  { label: 'EBL', value: 'EBL' },
];

const BloodLoss = ({
  data,
  disabled,
  handleSave,
  handleNote,
}: BloodLossProps) => {
  const rows = data || [];
  const [loss, setLoss] = useState({
    type: '',
    volume: '',
  });

  const handleVolume = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const decimalRegex = /^\d*\.?\d*$/;
    if (decimalRegex.test(value)) {
      setLoss({
        ...loss,
        [name]: value,
      });
    }
  };

  const handleType = (property: string, newValue: string) => {
    setLoss({
      ...loss,
      type: newValue,
    });
  };

  const createNote = () => {
    const note = `Blood Loss: 
        ${loss.type ? `type ${loss.type},` : ''}
        ${loss.volume ? `volume ${loss.volume} ml` : ''}
       at ${dayjs(new Date()).format('MM/DD/YYYY HH:mm:ss')}.`;

    handleNote(note);
  };

  const save = () => {
    const newResult: BloodLossInfo = {
      id: rows.length + 1,
      time: `${dayjs(new Date()).format('MM/DD/YYYY HH:mm')}`,
      volume: loss.volume,
      type: loss.type,
    };
    handleSave('bloodLoss', [...rows, newResult]);
    createNote();
    setLoss({
      type: '',
      volume: '',
    });
  };

  const columns: GridColDef<(typeof rows)[number]>[] = [
    {
      field: 'id',
      headerName: '#',
      headerAlign: 'left',
      flex: 0.5,
      headerClassName: 'bg-grayBackground',
      sortable: false,
    },
    {
      field: 'time',
      headerName: 'Time',
      headerAlign: 'left',
      flex: 1,
      headerClassName: 'bg-grayBackground',
      sortable: false,
    },
    {
      field: 'volume',
      headerName: 'Volume (ml)',
      headerAlign: 'left',
      flex: 1,
      headerClassName: 'bg-grayBackground',
      sortable: false,
    },
    {
      field: 'type',
      headerName: 'Type',
      headerAlign: 'left',
      flex: 1,
      headerClassName: 'bg-grayBackground',
      sortable: false,
    },
  ];

  const totalBlood = rows.reduce(
    (total: number, row: BloodLossInfo) => total + parseInt(row.volume),
    0
  );

  return (
    <div className="mb-4">
      <CollapsibleComponent label="Blood loss">
        <div className="flex flex-col h-full rounded-b-lg bg-grayCard px-2.5">
          <div
            className={customTwMerge(
              'flex flex-row justify-end',
              rows.length > 0 ? 'mt-[-30px]' : ''
            )}
          >
            {rows.length > 0 && (
              <ErrorRoundedIcon
                sx={{ marginTop: 0.5, marginRight: 1, color: colors.red }}
              />
            )}
            <Typography
              variant="body"
              text={`${rows.length > 0 ? `Total ${totalBlood} ml` : ''}`}
              customClass=" mt-1"
            />
          </div>
          {!disabled && (
            <>
              <TabsSelectComponent
                tabs={tabsType}
                value={loss.type}
                handleChange={handleType}
                property="type"
              />
              <div className="flex items-center gap-8">
                <BasicTextfield
                  id="volume"
                  variant="filled"
                  name="volume"
                  label="Volume (ml)"
                  value={loss.volume}
                  onChange={handleVolume}
                />
                <ButtonType
                  variant="contained"
                  onClick={save}
                  disabled={!loss.volume}
                  text="Add"
                  classes={'max-h-[30px]'}
                  icon
                  specificIcon={<AddIcon sx={{ fontSize: '15px' }} />}
                />
              </div>
            </>
          )}
          <div className="w-full h-fit my-4 grid">
            <StyledDataGrid
              rows={rows}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 5,
                  },
                },
              }}
              pageSizeOptions={[5]}
              disableRowSelectionOnClick
              autoHeight
              slots={{
                noRowsOverlay: () => (
                  <div className="flex justify-center items-center h-full">
                    No loss.
                  </div>
                ),
              }}
              sx={{
                backgroundColor: colors.white,
                overflow: 'auto',
                fontSize: '13px',
                textAlign: 'center',
              }}
            />
          </div>
        </div>
      </CollapsibleComponent>
    </div>
  );
};

export default BloodLoss;
