import { useQuery } from '@tanstack/react-query';

import { PracticeDTO } from '@aster/shared/dtos/practice';
import { createStorageKey } from '@aster/shared/utils/storage';

import { getS3ClientWithCredentials } from '../../documents/utils/get-s3-client-with-credentials';

import axios from '../../../app/axiosConfig';
import { downloadFile } from '../../documents/utils/download-file';

const getPracticeSettings = async (id: string) => {
  const { data: practiceSettings } = await axios.get(`/v2/practices/${id}`);

  if (!practiceSettings.id) return {} as PracticeDTO;

  const s3 = await getS3ClientWithCredentials();

  if (!s3) throw new Error('Permission required to upload files');

  const logoUrl = await downloadFile(
    createStorageKey({
      practiceId: practiceSettings.id,
      scope: 'assets',
      name: 'logo',
    }),
    'logo',
    () => {},
    false
  );

  if (logoUrl) practiceSettings.logoUrl = logoUrl;

  return practiceSettings;
};

export const usePracticeSettingsQuery = (id?: string, opts = {}) => {
  const { data: practiceSettings, ...rest } = useQuery({
    queryKey: ['practiceSettings'],
    queryFn: () => getPracticeSettings(id as string),
    enabled: !!id,
    ...opts,
  });

  return { practiceSettings, ...rest };
};
