import dayjs from 'dayjs';
import Typography from '../../../components/Typography';
import CollapsibleComponent from '../../../components/CollapsibleComponent';
import { customTwMerge } from '../../../utils';
import { BabyMilestonesInfo } from './types/baby.types';
import { MilestoneInfo } from './types/birth.types';

type BabyMilestonesProps = {
  data: BabyMilestonesInfo;
  disabled: boolean;
  handleNote: (note: string) => void;
  handleSave: (property: string, value: BabyMilestonesInfo) => void;
};

const BabyMilestones = ({
  data,
  disabled,
  handleSave,
  handleNote,
}: BabyMilestonesProps) => {
  const newNote = (title: string) => {
    const note = `Milestone: ${title} was at ${dayjs(new Date()).format(
      'MM/DD/YYYY HH:mm:ss'
    )}`;
    handleNote(note);
  };

  const handleClick = (name: string, title: string) => {
    handleSave('babyMilestones', { ...data, [name]: dayjs().toISOString() });
    newNote(title);
  };

  const Milestone = ({ title, date, name }: MilestoneInfo) => {
    return (
      <button
        className={customTwMerge(
          'flex justify-center items-center my-2.5 hover:bg-grayBackground rounded-xl w-full',
          disabled ? 'bg-grayCard pointer-events-none' : 'bg-white'
        )}
        onClick={() => handleClick(name, title)}
      >
        <div className="flex flex-grow items-start mx-2 my-3 px-2">
          <Typography
            text={title}
            variant="bodySmall"
            customClass="font-semibold py-1"
          />
        </div>

        <div className="flex items-center justify-center p-2 mr-4 rounded-xl bg-stone-50 text-ellipsis text-bodySmall">
          {date ? dayjs(date).format('MM/DD/YYYY HH:mm') : 'Date/Time'}
        </div>
      </button>
    );
  };

  return (
    <CollapsibleComponent label="Milestones" color="bg-beige">
      <div className="flex flex-col w-full px-2.5 rounded-b-lg bg-beige">
        <div className="pb-2.5 flex gap-2">
          <div className="flex flex-col w-1/2">
            <Milestone title="First cry" name="firstCry" date={data.firstCry} />
            <Milestone
              title="First Latch"
              name="firstLatch"
              date={data.firstLatch}
            />
          </div>
          <div className="flex flex-col w-1/2">
            <Milestone
              title="Skin-to-skin"
              name="skinToSkin"
              date={data.skinToSkin}
            />
            <Milestone
              title="Feeding start"
              name="feedingStart"
              date={data.feedingStart}
            />
          </div>
        </div>
      </div>
    </CollapsibleComponent>
  );
};

export default BabyMilestones;
