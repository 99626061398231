import { createStorageKey } from '@aster/shared/utils/storage';
import { downloadFile } from '../../documents/utils/download-file';

export const getLabsFileSignedURL = async (
  practiceID: string,
  labOrderID: string,
  pdfType: 'results' | 'requisition'
) => {
  const s3Key = createStorageKey({
    practiceId: practiceID,
    scope: 'labs',
    name: [labOrderID, `${pdfType}.pdf`].join('/'),
  });

  return downloadFile(s3Key, `${labOrderID}_${pdfType}.pdf`, undefined, false);
};
