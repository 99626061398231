import {
  AppointmentType,
  AppointmentTypeDisplayNames,
  CreateAppointmentDTO,
} from '@aster/shared/dtos/appointment';

export const getAppointmentType = (
  type: string
): CreateAppointmentDTO['type'] => {
  Object.entries(AppointmentTypeDisplayNames).forEach(([key, value]) => {
    if (value === type) {
      return key as CreateAppointmentDTO['type'];
    }
  });
  return AppointmentType.general as CreateAppointmentDTO['type'];
};
