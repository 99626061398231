import Typography from '../../../../../components/Typography';

export type EmptyStateProps = {
  icon: React.ReactNode;
  title: string;
  description: string;
  action?: React.ReactNode;
};

export const EmptyState = ({
  icon,
  title,
  description,
  action,
}: EmptyStateProps) => {
  return (
    <div className="w-full">
      <div className="flex-row items-center space-y-6">
        <div className="text-center text-[60px] text-asterMain relative h-[60px] leading-[0px]">
          <span className="absolute top-[2px] left-[calc(50%-26.5px)] opacity-40 h-[60px]">
            {icon}
          </span>
          <span className="absolute top-0 left-[calc(50%-26.5px)] h-[60px]">
            {icon}
          </span>
        </div>
        <div className="flex-row space-y-2">
          <Typography variant="h3" customClass="text-center font-normal">
            {title}
          </Typography>
          <Typography variant="body" customClass="text-center text-gray">
            {description}
          </Typography>
        </div>
        {action && <div className="text-center">{action}</div>}
      </div>
    </div>
  );
};
