import {
  LabTestDTO,
  TryVitalCreateOrderAOEAnswerDTO,
  TryVitalMarkerQuestionDTO,
} from '@aster/shared/dtos/labs';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useAvailableLabTests } from '../queries/query-available-lab-tests';
import { distance } from 'fastest-levenshtein';
import { useSelectedLabTestQuestions } from '../queries/get-lab-test-questions.query';
import { useParams } from 'react-router-dom';
import { usePatientValidForLabOrders } from '../queries/query-is-patient-valid-for-labs';

type CreateLabsOrderType = {
  selectedLabTest: LabTestDTO | null;
  labTestQuestions?: TryVitalMarkerQuestionDTO[];
  isLoadingQuestions: boolean;
  handleSelectLabTest: (labTest: LabTestDTO | null) => void;
  filteredLabTests: LabTestDTO[] | undefined;
  areLabTestsLoading: boolean;
  searchQuery: string;
  isValidPatient: boolean;
  isValidatingPatient: boolean;
  answers: Map<number, TryVitalCreateOrderAOEAnswerDTO>;
  onAnswerChange: (answer: string, questionId: number) => void;
  setSearchQuery: (query: string) => void;
  closeDialog: () => void;
};
const defaultState: CreateLabsOrderType = {
  selectedLabTest: null,
  labTestQuestions: undefined,
  isLoadingQuestions: false,
  isValidPatient: false,
  isValidatingPatient: false,
  answers: new Map(),
  onAnswerChange: () => {
    console.error('onAnswerChange function is not defined');
  },
  handleSelectLabTest: () => {
    console.error('handleSelectLabTest function is not defined');
  },
  filteredLabTests: [],
  areLabTestsLoading: false,
  searchQuery: '',
  setSearchQuery: () => {
    console.error('setSearchQuery function is not defined');
  },
  closeDialog: () => {
    console.error('closeDialog function is not defined');
  },
};

const CreateLabsOrderContext = createContext<CreateLabsOrderType>(defaultState);

function CreateLabsOrderProvider({
  children,
  closeDialog,
}: {
  children: React.ReactNode;
  closeDialog: () => void;
}) {
  const [selectedLabTest, setSelectedLabTest] = useState<LabTestDTO | null>(
    null
  );
  const handleSelectLabTest = (labTest: LabTestDTO | null) => {
    setSelectedLabTest(labTest);
  };
  const [searchQuery, setSearchQuery] = useState('');
  const { patient } = useParams();
  const { isValidPatient, isValidatingPatient } =
    usePatientValidForLabOrders(patient);
  const { availableLabTests, areLabTestsLoading } = useAvailableLabTests();
  const { labTestQuestions, isLoadingQuestions } = useSelectedLabTestQuestions(
    selectedLabTest?.id
  );
  const filteredLabTests = useMemo(() => {
    if (!searchQuery) return availableLabTests;
    return availableLabTests
      ?.map((labTest) => ({
        ...labTest,
        distance: distance(
          labTest.name.toLowerCase(),
          searchQuery.toLowerCase()
        ),
      }))
      .filter((labTest) => labTest.distance < labTest.name.length)
      .sort((a, b) => a.distance - b.distance);
  }, [availableLabTests, searchQuery]);

  const [answers, setAnswers] = useState<
    Map<number, TryVitalCreateOrderAOEAnswerDTO>
  >(
    () =>
      new Map(
        labTestQuestions?.map((question) => [
          question.id,
          {
            question_id: question.id,
            marker_id: question.markerId,
            answer: '',
          },
        ])
      )
  );

  useEffect(() => {
    if (labTestQuestions?.length) {
      setAnswers(
        new Map(
          labTestQuestions.map((question) => [
            question.id,
            {
              question_id: question.id,
              marker_id: question.markerId,
              answer: '',
            },
          ])
        )
      );
    } else {
      setAnswers(new Map());
    }
  }, [labTestQuestions]);

  const onAnswerChange = useCallback((answer: string, questionId: number) => {
    setAnswers((prev) =>
      prev.has(questionId)
        ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          new Map(prev.set(questionId, { ...prev.get(questionId)!, answer }))
        : prev
    );
  }, []);

  const value: CreateLabsOrderType = {
    answers,
    onAnswerChange,
    selectedLabTest,
    isValidPatient,
    isValidatingPatient,
    labTestQuestions,
    isLoadingQuestions,
    handleSelectLabTest,
    filteredLabTests,
    areLabTestsLoading,
    searchQuery,
    setSearchQuery,
    closeDialog,
  };

  return (
    <CreateLabsOrderContext.Provider value={value}>
      {children}
    </CreateLabsOrderContext.Provider>
  );
}

function useCreateLabsOrder() {
  const context = useContext(CreateLabsOrderContext);
  if (context === undefined) {
    throw new Error(
      'useCreateLabsOrder must be used within a CreateLabsOrderProvider'
    );
  }
  return context;
}

export { CreateLabsOrderProvider, useCreateLabsOrder };
