import { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ButtonType from '../../../components/Button';
import Typography from '../../../components/Typography';
import TabsSelectComponent from '../../../components/TabsSelectComponent';
import CollapsibleComponent from '../../../components/CollapsibleComponent';
import dayjs from 'dayjs';
import {
  apgarOptionScores,
  tabsActivity,
  tabsAppearance,
  tabsGrimace,
  tabsPulse,
  tabsRespiration,
} from './constants/apgar.constants';
import { ApgarInfo, TimeValues, Score } from './types/apgar.types';

type ApgarProps = {
  data: ApgarInfo[] | undefined;
  disabled: boolean;
  isGray?: boolean;
  handleNote: (note: string) => void;
  handleSave: (property: string, event: Partial<ApgarInfo>[]) => void;
};

function calculateScore(apgarInfo: Partial<ApgarInfo>) {
  let score = 0;

  score += apgarInfo.appearance ? apgarOptionScores[apgarInfo.appearance] : 0;
  score += apgarInfo.pulse ? apgarOptionScores[apgarInfo.pulse] : 0;
  score += apgarInfo.grimace ? apgarOptionScores[apgarInfo.grimace] : 0;
  score += apgarInfo.activity ? apgarOptionScores[apgarInfo.activity] : 0;
  score += apgarInfo.respiration ? apgarOptionScores[apgarInfo.respiration] : 0;

  return score;
}

const Apgar = ({
  data,
  disabled,
  handleSave,
  handleNote,
  isGray,
}: ApgarProps) => {
  // Removed score1, score5, and score10 states
  const [apgarScores, setApgarScores] = useState<Partial<ApgarInfo>[]>(
    data || []
  );
  const [openSelect, setOpenSelect] = useState(false);
  const [currentTime, setCurrentTime] = useState<TimeValues>('1 min');
  const [currentApgar, setCurrentApgar] = useState<Partial<ApgarInfo>>({
    score: 0,
  });

  const score1 =
    apgarScores.find((score) => score.time === '1 min')?.score || 0;
  const score5 =
    apgarScores.find((score) => score.time === '5 min')?.score || 0;
  const score10 =
    apgarScores.find((score) => score.time === '10 min')?.score || 0;

  const handleApgar = (property: string, newValue: string) => {
    setCurrentApgar((prevState) => ({ ...prevState, [property]: newValue }));
  };

  const saveScore = () => {
    const score = calculateScore(currentApgar);

    const updatedTemp = { ...currentApgar, score, time: currentTime };

    setApgarScores((prevState) => {
      const updatedScores = prevState.map((item) => {
        if (item.time === currentTime) {
          return updatedTemp;
        }
        return item;
      });

      if (!updatedScores.some((item) => item.time === currentTime)) {
        updatedScores.push(updatedTemp);
      }
      handleSave('apgar', updatedScores);
      return updatedScores;
    });

    const note = `Apgar: score ${score} at ${dayjs(new Date()).format(
      'MM/DD/YYYY HH:mm:ss'
    )} (${currentTime})`;
    handleNote(note);

    setCurrentApgar({
      score: 0,
    });
    setOpenSelect(false);
  };

  const ScoreButton = ({ time, score }: Score) => {
    return (
      <button
        className={`flex flex-col items-center justify-center my-2.5 hover:bg-grayCard ${
          disabled ? 'bg-grayCard pointer-events-none' : 'bg-white'
        } rounded-xl w-full`}
        disabled={disabled}
        onClick={() => {
          setCurrentTime(time);
          setOpenSelect(true);
        }}
      >
        <div className="flex gap-0 p-1">
          <div className="flex flex-col justify-between content-center items-center">
            <Typography
              text={time}
              variant="body"
              customClass="font-semibold ml-3 text-gray-500"
            />
            <Typography
              text={score.toString()}
              variant="h3"
              customClass="font-semibold ml-3"
            />
          </div>
        </div>
      </button>
    );
  };

  return (
    <CollapsibleComponent
      label="APGAR Score"
      color={isGray ? 'bg-grayCard' : 'bg-beige'}
    >
      <div
        className={`flex flex-col h-full rounded-b-lg ${
          isGray ? 'bg-grayCard' : 'bg-beige'
        }  px-2.5`}
      >
        <Typography
          text="Click the buttons below to view and select the Apgar score"
          variant="bodySmall"
          customClass="font-semibold text-gray-500"
        />
        <div className="flex flex-row gap-2">
          <ScoreButton time="1 min" score={score1} />
          <ScoreButton time="5 min" score={score5} />
          <ScoreButton time="10 min" score={score10} />
        </div>

        <table className="w-full my-2 p-2">
          <thead className="bg-aster rounded">
            <tr>
              <th key="criteria" className="py-1 text-white text-left px-2 ">
                Criteria
              </th>
              <th key="1min" className="py-1 text-white text-left px-2">
                1 Minute
              </th>
              <th key="5min" className="py-1 text-white text-left px-2">
                5 Minutes
              </th>
              <th key="10min" className="py-1 text-white text-left px-2">
                10 Minutes
              </th>
            </tr>
          </thead>
          <tbody className="bg-white">
            <tr>
              <td className="py-1 text-gray-500 text-left px-2">Appearance</td>
              <td className="py-1 text-gray-500 text-left px-2">
                {apgarScores.find((score) => score.time === '1 min')
                  ?.appearance || '-'}
              </td>
              <td className="py-1 text-gray-500 text-left px-2">
                {apgarScores.find((score) => score.time === '5 min')
                  ?.appearance || '-'}
              </td>
              <td className="py-1 text-gray-500 text-left px-2">
                {apgarScores.find((score) => score.time === '10 min')
                  ?.appearance || '-'}
              </td>
            </tr>
            <tr>
              <td className="py-1 text-gray-500 text-left px-2">Pulse</td>
              <td className="py-1 text-gray-500 text-left px-2">
                {apgarScores.find((score) => score.time === '1 min')?.pulse ||
                  '-'}
              </td>
              <td className="py-1 text-gray-500 text-left px-2">
                {apgarScores.find((score) => score.time === '5 min')?.pulse ||
                  '-'}
              </td>
              <td className="py-1 text-gray-500 text-left px-2">
                {apgarScores.find((score) => score.time === '10 min')?.pulse ||
                  '-'}
              </td>
            </tr>
            <tr>
              <td className="py-1 text-gray-500 text-left px-2">Grimace</td>
              <td className="py-1 text-gray-500 text-left px-2">
                {apgarScores.find((score) => score.time === '1 min')?.grimace ||
                  '-'}
              </td>
              <td className="py-1 text-gray-500 text-left px-2">
                {apgarScores.find((score) => score.time === '5 min')?.grimace ||
                  '-'}
              </td>
              <td className="py-1 text-gray-500 text-left px-2">
                {apgarScores.find((score) => score.time === '10 min')
                  ?.grimace || '-'}
              </td>
            </tr>
            <tr className="">
              <td className="py-1 text-gray-500 text-left px-2">Activity</td>
              <td className="py-1 text-gray-500 text-left px-2">
                {apgarScores.find((score) => score.time === '1 min')
                  ?.activity || '-'}
              </td>
              <td className="py-1 text-gray-500 text-left px-2">
                {apgarScores.find((score) => score.time === '5 min')
                  ?.activity || '-'}
              </td>
              <td className="py-1 text-gray-500 text-left px-2">
                {apgarScores.find((score) => score.time === '10 min')
                  ?.activity || '-'}
              </td>
            </tr>
            <tr>
              <td className="py-1 text-gray-500 text-left px-2">Respiration</td>
              <td className="py-1 text-gray-500 text-left px-2">
                {apgarScores.find((score) => score.time === '1 min')
                  ?.respiration || '-'}
              </td>
              <td className="py-1 text-gray-500 text-left px-2">
                {apgarScores.find((score) => score.time === '5 min')
                  ?.respiration || '-'}
              </td>
              <td className="py-1 text-gray-500 text-left px-2">
                {apgarScores.find((score) => score.time === '10 min')
                  ?.respiration || '-'}
              </td>
            </tr>
          </tbody>
        </table>

        <Dialog
          open={openSelect}
          keepMounted
          onClose={() => {
            setOpenSelect(false);
          }}
          fullWidth
        >
          <div className="flex flex-col items-center">
            <DialogTitle
              sx={{ m: 0, p: 2, height: 10, fontWeight: 600 }}
              id="customized-dialog-title"
            >
              APGAR Score
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={() => setOpenSelect(false)}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent className="flex flex-col items-center">
              <div className="flex flex-col h-full rounded-b-lg bg-grayCard px-10 py-4">
                <TabsSelectComponent
                  question="Appearance"
                  tabs={tabsAppearance}
                  value={currentApgar.appearance}
                  handleChange={handleApgar}
                  property="appearance"
                />
                <TabsSelectComponent
                  question="Pulse"
                  tabs={tabsPulse}
                  value={currentApgar.pulse}
                  handleChange={handleApgar}
                  property="pulse"
                />

                <TabsSelectComponent
                  question="Grimace"
                  tabs={tabsGrimace}
                  value={currentApgar.grimace}
                  handleChange={handleApgar}
                  property="grimace"
                />
                <TabsSelectComponent
                  question="Activity"
                  tabs={tabsActivity}
                  value={currentApgar.activity}
                  handleChange={handleApgar}
                  property="activity"
                />
                <TabsSelectComponent
                  question="Respiration"
                  tabs={tabsRespiration}
                  value={currentApgar.respiration}
                  handleChange={handleApgar}
                  property="respiration"
                />
              </div>
            </DialogContent>
            <ButtonType
              variant="contained"
              onClick={saveScore}
              text="Save"
              classes="mb-4 ml-[80%]"
            />
          </div>
        </Dialog>
      </div>
    </CollapsibleComponent>
  );
};

export default Apgar;
