import { customTwMerge as twMerge } from '../utils';

export type Variant =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'body'
  | 'bodySmall'
  | 'bodyMedium'
  | 'meta';

type TypographyProps = {
  variant: Variant;
  text?: string;
  customClass?: string;
  children?: React.ReactNode;
};

const Typography = ({
  variant,
  children,
  text,
  customClass,
}: TypographyProps) => {
  switch (variant) {
    case 'h1':
      return (
        <h1
          className={twMerge(
            'text-h1 leading-[3rem] font-semibold',
            customClass
          )}
        >
          {children ?? text}
        </h1>
      );
    case 'h2':
      return (
        <h2
          className={twMerge(
            'text-h2 leading-[38.4px] font-semibold',
            customClass
          )}
        >
          {children ?? text}
        </h2>
      );
    case 'h3':
      return (
        <h3
          className={twMerge(
            'text-h3 leading-[2.1rem] font-semibold',
            customClass
          )}
        >
          {children ?? text}
        </h3>
      );
    case 'h4':
      return (
        <h4
          className={twMerge(
            'text-h4 leading-[28.8px] font-semibold',
            customClass
          )}
        >
          {children ?? text}
        </h4>
      );
    case 'h5':
      return (
        <h5
          className={twMerge(
            'text-h5 leading-[24px] font-semibold',
            customClass
          )}
        >
          {children ?? text}
        </h5>
      );
    case 'h6':
      return (
        <h2
          className={twMerge(
            'text-h6 leading-[1.2rem] font-semibold',
            customClass
          )}
        >
          {children ?? text}
        </h2>
      );
    case 'body':
      return (
        <p
          className={twMerge(
            'text-body leading-[24px] font-normal',
            customClass
          )}
        >
          {children ?? text}
        </p>
      );
    case 'bodyMedium':
      return (
        <p
          className={twMerge(
            'text-bodyMedium leading-5 font-normal',
            customClass
          )}
        >
          {children ?? text}
        </p>
      );
    case 'bodySmall':
      return (
        <p
          className={twMerge(
            'text-bodySmall leading-[1.125rem] font-normal',
            customClass
          )}
        >
          {children ?? text}
        </p>
      );
    case 'meta':
      return (
        <p
          className={`${twMerge(
            'text-p10 leading-[1.5] font-normal',
            customClass
          )}`}
        >
          {children ?? text}
        </p>
      );
  }
};

export default Typography;
