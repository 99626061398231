import CircularProgress from '@mui/material/CircularProgress';
import { useCreateLabsOrder } from './LabsOrderContext';
import { LabTestCard } from './LabTestCard';

export const LabTests = () => {
  const { filteredLabTests, areLabTestsLoading } = useCreateLabsOrder();

  if (areLabTestsLoading) {
    return <CircularProgress className="mx-auto" size="1.5rem" />;
  }

  return (
    <div className="grid grid-cols-2 gap-3 w-full overflow-auto">
      {filteredLabTests?.map((labTest) => (
        <LabTestCard key={labTest.id} labTest={labTest} />
      ))}
    </div>
  );
};
