import { LabTestDTO } from '@aster/shared/dtos/labs';
import axios from '../../../app/axiosConfig';
import { useQuery } from '@tanstack/react-query';

const fetchLabTests = async () => {
  const response = await axios.get<LabTestDTO[]>(`/v2/labs/tests`);
  return response.data;
};
export function useAvailableLabTests() {
  const { data: availableLabTests, isLoading: areLabTestsLoading } = useQuery({
    queryKey: ['labTests'],
    queryFn: fetchLabTests,
  });

  return { availableLabTests, areLabTestsLoading };
}
