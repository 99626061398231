import * as React from 'react';
import { FormControlLabel, Switch, styled, useMediaQuery } from '@mui/material';
import { colors } from '../theme';
import { twMerge } from 'tailwind-merge';

type Placement = 'start' | 'top' | 'end' | 'bottom';
type SwitchProps = {
  checked: boolean;
  label?: string;
  disabled?: boolean;
  defaultChecked?: boolean;
  placement?: Placement;
  onChange?: any;
  icon?: any;
  space?: number;
  classes?: any;
};

const GreenSwitch = styled(Switch)(() => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: colors.white,
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: colors.green,
  },
}));

const BasicSwitches = (props: SwitchProps) => {
  const isMobile = useMediaQuery('(max-width:600px)');
  const {
    checked,
    label,
    disabled,
    defaultChecked,
    placement,
    onChange,
    icon,
    space,
    classes,
  } = props;
  return (
    <div className={twMerge('flex w-[100%]', classes)}>
      {icon && <div className="ml-[15px] w-1/6">{icon}</div>}
      <div className="w-5/6">
        <FormControlLabel
          control={
            <GreenSwitch
              checked={checked}
              onChange={onChange}
              sx={{
                marginLeft: isMobile ? 4 : space ? space : 2,
              }}
            />
          }
          label={label}
          disabled={disabled}
          defaultChecked={defaultChecked}
          labelPlacement={placement || 'start'}
        />
      </div>
    </div>
  );
};

export default BasicSwitches;
