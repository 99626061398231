import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import ButtonType from '../../../components/Button';
import Typography from '../../../components/Typography';
import { Badge } from '@mui/material';
import HouseMedical from '../../../assets/icons/HouseMedical';
import Capsules from '../../../assets/icons/Capsules';
import UserGroup from '../../../assets/icons/UserGroup';
import PoweredBy from '../../../assets/powered-by.webp';
import DoseSpot from '../../../assets/dosespot.webp';
import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

const GettingStarted = ({ incrementStep }: { incrementStep: () => void }) => {
  const StepBox = ({
    className,
    title,
    description,
    icon,
    stepNumber,
  }: {
    className: string;
    title: string;
    description: string;
    icon: ReactNode;
    stepNumber: number;
  }) => (
    <div
      className={twMerge(
        'w-[192px] h-[146px] flex flex-col border rounded-[10px] p-3 relative',
        className
      )}
    >
      <div className="flex justify-between">
        <Typography
          customClass="font-semibold"
          variant="bodyMedium"
          text={title}
        />
        <Badge
          badgeContent={stepNumber}
          color="primary"
          sx={{
            top: 10,
            right: 9,
          }}
        />
      </div>
      <Typography
        customClass="text-asterGray mt-2"
        variant="bodySmall"
        text={description}
      />
      <div className="absolute bottom-4 right-[9px]">{icon}</div>
    </div>
  );

  return (
    <>
      <div className="flex flex-col items-end absolute top-6 right-6">
        <img src={PoweredBy} className="max-w-[45px] w-full h-full" />
        <img src={DoseSpot} className="mt-1 max-w-[90px] w-full h-full" />
      </div>
      <DialogContent className="flex flex-col items-center m-0 p-10">
        <Typography
          variant={'h4'}
          customClass="font-semibold mb-5"
          text={'Getting Started'}
        />
        <Typography
          customClass="text-asterGray text-center max-w-[500px]"
          variant="bodyMedium"
          text={
            'To get started prescribing for your patients we must set up your DoseSpot account, please follow the instructions.'
          }
        />
        <div className="flex gap-x-8 mt-12">
          <StepBox
            className="border-pastelPink"
            title="Add your practice"
            description="Share details about your practice and licensing information."
            icon={<HouseMedical />}
            stepNumber={1}
          />
          <StepBox
            className="border-pastelBlue"
            title="Sync your patients"
            description="Add patients you will be prescribing for during treatment."
            icon={<UserGroup />}
            stepNumber={2}
          />
          <StepBox
            className="border-pastelOrange"
            title="Start prescribing"
            description="Open the DoseSpot modal and start prescribing medication."
            icon={<Capsules />}
            stepNumber={3}
          />
        </div>
      </DialogContent>
      <DialogActions className="flex justify-center">
        <ButtonType
          classes="min-w-[292px]"
          variant="contained"
          onClick={incrementStep}
          text={'Continue'}
        />
      </DialogActions>
    </>
  );
};

export default GettingStarted;
