import { createContext, useContext, useReducer } from 'react';
import { usePatientLabOrders } from './queries/query-patient-lab-orders';
import { LabsOrderDTO } from '@aster/shared/dtos/labs';

const actionTypes = {
  openLabOrderDetails: 'OPEN_LAB_ORDER_DETAILS',
  closeLabOrderDetails: 'CLOSE_LAB_ORDER_DETAILS',
  openLabsOrderCreationWizard: 'OPEN_LABS_ORDER_CREATION_WIZARD',
  closeLabsOrderCreationWizard: 'CLOSE_LABS_ORDER_CREATION_WIZARD',
  showCheckoutQuestions: 'SHOW_CHECKOUT_QUESTIONS',
  addNewOrderID: 'ADD_NEW_ORDER_ID',
} as const;

type ActionTypes = typeof actionTypes;

type LabsState = {
  selectedLabOrderID: string | null;
  isLabsOrderCreationWizardOpen: boolean;
  newOrderIDs: string[];
  isCheckoutQuestionsVisible: boolean;
};

const defaultState: LabsState = {
  selectedLabOrderID: null,
  isLabsOrderCreationWizardOpen: false,
  newOrderIDs: [],
  isCheckoutQuestionsVisible: false,
};

function labsReducer(
  state: LabsState,
  action: { type: ActionTypes[keyof ActionTypes]; payload: string | null }
): LabsState {
  switch (action.type) {
    case 'OPEN_LAB_ORDER_DETAILS':
      return {
        ...state,
        selectedLabOrderID: action.payload,
        isLabsOrderCreationWizardOpen: false,
      };
    case 'CLOSE_LAB_ORDER_DETAILS':
      return {
        ...state,
        selectedLabOrderID: null,
        isLabsOrderCreationWizardOpen: false,
      };
    case 'OPEN_LABS_ORDER_CREATION_WIZARD':
      return {
        ...state,
        selectedLabOrderID: null,
        isLabsOrderCreationWizardOpen: true,
      };
    case 'CLOSE_LABS_ORDER_CREATION_WIZARD':
      return {
        ...state,
        selectedLabOrderID: null,
        isLabsOrderCreationWizardOpen: false,
        isCheckoutQuestionsVisible: false,
      };
    case 'ADD_NEW_ORDER_ID':
      if (action.payload === null) {
        return state;
      }
      return {
        ...state,
        newOrderIDs: [...state.newOrderIDs, action.payload],
      };
    case 'SHOW_CHECKOUT_QUESTIONS':
      return {
        ...state,
        isCheckoutQuestionsVisible: true,
      };
    default: {
      throw new Error(`Unhandled type: ${action.type}`);
    }
  }
}

function useLabsReducer() {
  const [
    {
      selectedLabOrderID,
      isLabsOrderCreationWizardOpen,
      newOrderIDs,
      isCheckoutQuestionsVisible,
    },
    dispatch,
  ] = useReducer(labsReducer, defaultState);

  const openLabOrderDetails = (labsOrderID: string) =>
    dispatch({ type: actionTypes.openLabOrderDetails, payload: labsOrderID });
  const closeLabOrderDetails = () =>
    dispatch({ type: actionTypes.closeLabOrderDetails, payload: null });
  const openLabsOrderCreationWizard = () =>
    dispatch({ type: actionTypes.openLabsOrderCreationWizard, payload: null });
  const closeLabsOrderCreationWizard = () =>
    dispatch({ type: actionTypes.closeLabsOrderCreationWizard, payload: null });
  const addNewOrderID = (orderID: string) =>
    dispatch({ type: actionTypes.addNewOrderID, payload: orderID });
  const showCheckoutQuestions = () =>
    dispatch({ type: actionTypes.showCheckoutQuestions, payload: null });

  return {
    selectedLabOrderID,
    isLabsOrderCreationWizardOpen,
    isCheckoutQuestionsVisible,
    newOrderIDs,
    openLabOrderDetails,
    closeLabOrderDetails,
    openLabsOrderCreationWizard,
    closeLabsOrderCreationWizard,
    showCheckoutQuestions,
    addNewOrderID,
  };
}

type LabsType = ReturnType<typeof useLabsReducer> & {
  labOrders: LabsOrderDTO[] | undefined;
  pendingLabOrders: LabsOrderDTO[] | undefined;
  labOrdersWithResults: LabsOrderDTO[] | undefined;
  isLoadingOrders: boolean;
  addNewOrderID: (orderID: string) => void;
};

const defaultLabsState: LabsType = {
  ...defaultState,
  openLabOrderDetails: () => {
    console.error('openLabOrderDetails function is not defined');
  },
  closeLabOrderDetails: () => {
    console.error('closeLabOrderDetails function is not defined');
  },
  openLabsOrderCreationWizard: () => {
    console.error('openLabsOrderCreationWizard function is not defined');
  },
  closeLabsOrderCreationWizard: () => {
    console.error('closeLabsOrderCreationWizard function is not defined');
  },
  addNewOrderID: () => {
    console.error('addNewOrderID function is not defined');
  },
  showCheckoutQuestions: () => {
    console.error('showCheckoutQuestions function is not defined');
  },
  labOrders: undefined,
  pendingLabOrders: undefined,
  labOrdersWithResults: undefined,
  isLoadingOrders: false,
};

const LabsContext = createContext<LabsType>(defaultLabsState);

function LabsProvider({
  children,
  patientID,
}: {
  children: React.ReactNode;
  patientID?: string;
}) {
  const reducer = useLabsReducer();

  const { labOrders, isLoadingOrders } = usePatientLabOrders(patientID);

  const pendingLabOrders = labOrders?.filter(
    (order) => order.status !== 'completed'
  );

  const labOrdersWithResults = labOrders?.filter(
    (order) => order.status === 'completed'
  );

  const value = {
    ...reducer,
    labOrders,
    pendingLabOrders,
    labOrdersWithResults,
    isLoadingOrders,
  };

  return <LabsContext.Provider value={value}>{children}</LabsContext.Provider>;
}

function useLabs() {
  const context = useContext(LabsContext);
  if (context === undefined) {
    throw new Error('useLabs must be used within a LabsContext');
  }
  return context;
}

export { LabsProvider, useLabs };
