import SearchInput from '../../../components/SearchInput';
import { useCreateLabsOrder } from './LabsOrderContext';
import Typography from '../../../components/Typography';
import { LabTests } from './LabTests';

export const SelectOrder = () => {
  const { searchQuery, setSearchQuery } = useCreateLabsOrder();

  return (
    <section className="flex flex-col mx-6 my-5 overflow-hidden">
      <SearchInput
        value={searchQuery}
        onChange={(e) => {
          setSearchQuery(e.currentTarget.value);
        }}
        placeholder="Search lab tests"
      />
      <Typography
        variant="body"
        text="Available Panels"
        customClass="font-semibold my-4"
      />
      <LabTests />
    </section>
  );
};
