const LabIllustration = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="184"
    height="142"
    viewBox="0 0 184 142"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Group 23">
      <g id="SHELVES">
        <path
          id="Vector"
          d="M139.115 52.6033H130.424V70.4533H139.115V52.6033Z"
          fill="#E4E4E4"
        />
        <path
          id="Vector_2"
          d="M167.757 52.6033H143.944V57.2845H167.757V52.6033Z"
          fill="#FFC4EA"
        />
        <path
          id="Vector_3"
          d="M174.148 63.8679H152.353V70.4532H174.148V63.8679Z"
          fill="url(#paint0_linear_15199_52304)"
        />
        <path
          id="Vector_4"
          d="M174.148 57.2827H152.353V63.868H174.148V57.2827Z"
          fill="#69BDFF"
        />
        <path
          id="Vector_5"
          d="M167.757 57.2827H152.353V63.868L167.757 57.2827Z"
          fill="url(#paint1_linear_15199_52304)"
        />
        <path
          id="Vector_6"
          d="M183.808 69.5784H118.216V71.3284H183.808V69.5784Z"
          fill="#E4E4E4"
        />
        <path id="Vector_7" d="M83 140H4V142H83V140Z" fill="#E4E4E4" />
        <path id="Vector_8" d="M88 119H27V121H88V119Z" fill="#E4E4E4" />
        <path
          id="Vector_9"
          d="M97.433 0H83.1653V24.2655H97.433V0Z"
          fill="#E4E4E4"
        />
        <path
          id="Vector_10"
          d="M86.5165 12.8047H75.0557V24.2654H86.5165V12.8047Z"
          fill="#E55623"
        />
        <path
          id="Vector_11"
          d="M128.637 10.5474H103.695V24.2656H128.637V10.5474Z"
          fill="#FFC4EA"
        />
        <path
          id="Vector_12"
          d="M142.1 8.18823C143.23 8.18823 144.313 8.637 145.112 9.43581C145.911 10.2346 146.36 11.318 146.36 12.4477V22.5382C146.36 22.7653 146.315 22.9901 146.228 23.1999C146.141 23.4097 146.014 23.6003 145.853 23.7608C145.693 23.9214 145.502 24.0487 145.292 24.1356C145.082 24.2225 144.858 24.2672 144.631 24.2672H139.568C139.109 24.2672 138.669 24.0851 138.345 23.7608C138.021 23.4366 137.839 22.9968 137.839 22.5382V12.446C137.839 11.8866 137.949 11.3328 138.164 10.8161C138.378 10.2994 138.692 9.82994 139.088 9.43457C139.483 9.03921 139.953 8.72564 140.47 8.5118C140.987 8.29795 141.541 8.188 142.1 8.18823Z"
          fill="url(#paint2_linear_15199_52304)"
        />
        <path
          id="Vector_13"
          d="M141.213 5.73828H142.989C143.141 5.73828 143.286 5.79857 143.394 5.90589C143.501 6.01321 143.561 6.15876 143.561 6.31053V8.45603H140.642V6.30178C140.645 6.15184 140.706 6.00878 140.812 5.9034C140.919 5.79801 141.063 5.73872 141.213 5.73828Z"
          fill="url(#paint3_linear_15199_52304)"
        />
        <path
          id="Vector_14"
          d="M154.248 8.18823C155.378 8.18823 156.462 8.637 157.26 9.43581C158.059 10.2346 158.508 11.318 158.508 12.4477V22.5382C158.508 22.9968 158.326 23.4366 158.002 23.7608C157.677 24.0851 157.238 24.2672 156.779 24.2672H151.716C151.258 24.2672 150.818 24.0851 150.494 23.7608C150.169 23.4366 149.987 22.9968 149.987 22.5382V12.446C149.987 11.8866 150.098 11.3328 150.312 10.8161C150.526 10.2994 150.84 9.82994 151.236 9.43457C151.632 9.03921 152.101 8.72564 152.618 8.5118C153.135 8.29795 153.689 8.188 154.248 8.18823Z"
          fill="url(#paint4_linear_15199_52304)"
        />
        <path
          id="Vector_15"
          d="M153.373 5.73828H155.15C155.302 5.73828 155.447 5.79857 155.554 5.90589C155.662 6.01321 155.722 6.15876 155.722 6.31053V8.45603H152.803V6.30178C152.805 6.15184 152.866 6.00878 152.973 5.9034C153.08 5.79801 153.224 5.73872 153.373 5.73828Z"
          fill="url(#paint5_linear_15199_52304)"
        />
        <path
          id="Vector_16"
          d="M166.413 8.18824C167.542 8.18824 168.626 8.63701 169.425 9.43582C170.224 10.2346 170.672 11.3181 170.672 12.4477V22.5382C170.672 22.9968 170.49 23.4366 170.166 23.7608C169.842 24.0851 169.402 24.2672 168.943 24.2672H163.874C163.415 24.2672 162.975 24.0851 162.651 23.7608C162.327 23.4366 162.145 22.9968 162.145 22.5382V12.446C162.145 11.886 162.255 11.3316 162.47 10.8145C162.685 10.2973 162.999 9.82756 163.396 9.4321C163.792 9.03664 164.263 8.72323 164.78 8.5098C165.298 8.29636 165.853 8.18709 166.413 8.18824Z"
          fill="url(#paint6_linear_15199_52304)"
        />
        <path
          id="Vector_17"
          d="M165.525 5.73828H167.302C167.454 5.73828 167.599 5.79857 167.706 5.90589C167.814 6.01321 167.874 6.15876 167.874 6.31053V8.45603H164.955V6.30178C164.957 6.15184 165.018 6.00878 165.125 5.9034C165.232 5.79801 165.376 5.73872 165.525 5.73828Z"
          fill="url(#paint7_linear_15199_52304)"
        />
        <path
          id="Vector_18"
          d="M183.809 24.2654H71.61V26.0154H183.809V24.2654Z"
          fill="#E4E4E4"
        />
        <path
          id="Vector_19"
          d="M0.391998 67.9507C0.443476 67.9507 0.494445 67.9608 0.542004 67.9805C0.589564 68.0002 0.632784 68.0291 0.669185 68.0655C0.705585 68.1019 0.734454 68.1451 0.754153 68.1927C0.773853 68.2402 0.783997 68.2912 0.783997 68.3427V69.4382H0V68.3374C0.00138029 68.2344 0.0432837 68.136 0.116649 68.0636C0.190014 67.9913 0.288937 67.9507 0.391998 67.9507Z"
          fill="url(#paint8_linear_15199_52304)"
        />
        <path
          id="Vector_20"
          d="M1.70099 67.9507C1.75247 67.9507 1.80345 67.9608 1.85101 67.9805C1.89857 68.0002 1.94177 68.0291 1.97818 68.0655C2.01458 68.1019 2.04345 68.1451 2.06315 68.1927C2.08285 68.2402 2.09299 68.2912 2.09299 68.3427V69.4382H1.30899V68.3374C1.31037 68.2344 1.35228 68.136 1.42565 68.0636C1.49902 67.9913 1.59793 67.9507 1.70099 67.9507Z"
          fill="url(#paint9_linear_15199_52304)"
        />
      </g>
      <g id="WOMAN">
        <path
          id="Vector_21"
          d="M32.3067 37.5374L56.8382 58.3116V140.154L14.3972 139.942L32.3067 37.5374Z"
          fill="#FFC4EA"
        />
        <path
          id="Vector_22"
          d="M111.006 69.5958L80.9235 103.546C78.8966 105.835 76.2888 107.535 73.3757 108.465C70.4626 109.395 67.3524 109.521 64.3738 108.829L69.048 140.154H56.8383V52.6033L71.61 77.8033L91.308 55.4383L111.006 69.5958Z"
          fill="url(#paint10_linear_15199_52304)"
        />
        <path
          id="Vector_23"
          d="M56.8383 52.6032V58.3117L32.3068 37.5374L34.062 33.4827L47.194 36.1514L56.8383 52.6032Z"
          fill="url(#paint11_linear_15199_52304)"
        />
        <path
          id="Vector_24"
          d="M50.8777 42.4338L47.873 50.7183L56.8382 58.3116V52.6031L50.8777 42.4338Z"
          fill="url(#paint12_linear_15199_52304)"
        />
        <path
          id="Vector_25"
          d="M71.61 77.8031L62.055 86.9311L67.3015 70.4531L71.61 77.8031Z"
          fill="url(#paint13_linear_15199_52304)"
        />
        <path
          id="Vector_26"
          d="M91.3079 55.4381C93.3222 53.1193 113.958 66.2636 111.006 69.5938C109.273 71.5556 89.2937 57.7568 91.3079 55.4381Z"
          fill="url(#paint14_linear_15199_52304)"
        />
        <path
          id="Vector_27"
          d="M134.223 32.7197H112.14C111.514 32.7197 111.006 33.2274 111.006 33.8537V44.9137C111.006 45.54 111.514 46.0477 112.14 46.0477H134.223C134.85 46.0477 135.357 45.54 135.357 44.9137V33.8537C135.357 33.2274 134.85 32.7197 134.223 32.7197Z"
          fill="#69BDFF"
        />
        <path
          id="Vector_28"
          d="M126.292 38.2304V40.5387H124.336V42.4934H122.028V40.5387H120.073V38.2304H122.028V36.2739H124.336V38.2304H126.292Z"
          fill="#E55623"
        />
        <path
          id="Vector_29"
          d="M126.983 55.4382C126.983 55.4382 121.067 64.8147 108.129 64.3474L105.285 67.6584C98.9555 64.0692 89.9202 57.0342 91.308 55.4382L100.45 44.2014C101.993 42.3043 104.118 40.9689 106.496 40.4022L111.006 39.3259V42.8259C111.006 42.8259 107.331 46.0389 109.583 48.4259C112.663 51.6967 122.777 45.8902 122.777 45.8902L126.983 55.4382Z"
          fill="url(#paint15_linear_15199_52304)"
        />
        <path
          id="Vector_30"
          d="M108.129 64.3491L105.285 67.6601C98.9555 64.0708 89.9202 57.0358 91.308 55.4398L93.9645 52.1743C93.9645 52.1743 98.6615 57.7131 108.129 64.3491Z"
          fill="url(#paint16_linear_15199_52304)"
        />
        <path
          id="Vector_31"
          d="M123.949 48.5571C122.855 49.3726 118.564 52.3861 114.189 53.0161L114.116 52.4911C118.456 51.8663 122.866 48.7093 123.732 48.0583L123.949 48.5571Z"
          fill="#673606"
        />
        <path
          id="Vector_32"
          d="M125.008 50.9583C123.839 51.821 119.609 54.7575 115.297 55.3788L115.224 54.8538C119.487 54.2413 123.813 51.1788 124.789 50.4578L125.008 50.9583Z"
          fill="#673606"
        />
        <path
          id="Vector_33"
          d="M126.061 53.3487C124.953 54.2237 120.671 57.3737 116.305 57.9984L116.23 57.4734C120.571 56.8487 124.98 53.5272 125.842 52.8464L126.061 53.3487Z"
          fill="#673606"
        />
        <path
          id="Vector_34"
          d="M42.7962 68.7715L31.5525 93.8035L56.8382 101.649L42.7962 68.7715Z"
          fill="url(#paint17_linear_15199_52304)"
        />
        <path
          id="Vector_35"
          d="M49.9222 52.9041L31.5472 93.8034L56.7962 101.201L43.5697 119.126H18.7197C8.19873 119.126 1.15673 108.3 5.41973 98.6824L32.2997 37.5374L49.9222 52.9041Z"
          fill="url(#paint18_linear_15199_52304)"
        />
        <path
          id="Vector_36"
          d="M65.0195 103.752C68.9395 105.122 80.2935 110.446 84.0158 119.126H53.9735L65.0195 103.752Z"
          fill="url(#paint19_linear_15199_52304)"
        />
        <path
          id="Vector_37"
          d="M81.396 119.126H80.85C79.8927 115.936 74.6322 112.301 74.095 111.928L74.7267 111.729C75.7068 112.423 76.6485 113.17 77.5477 113.967C79.7107 115.896 81.004 117.632 81.396 119.126Z"
          fill="#673606"
        />
        <path
          id="Vector_38"
          d="M77.3938 119.126H76.8495C76.006 116.326 71.8778 113.759 70.6195 113.029L71.2915 112.815C72.0983 113.303 72.8766 113.837 73.6225 114.413C75.733 116.046 77.0018 117.631 77.3938 119.126Z"
          fill="#673606"
        />
        <path
          id="Vector_39"
          d="M73.612 119.117L73.087 119.133C73.0747 118.716 72.618 117.749 69.706 115.965C68.481 115.215 67.256 114.585 66.6452 114.287L67.368 114.058C69.307 115.038 73.5595 117.388 73.612 119.117Z"
          fill="#673606"
        />
        <path
          id="Vector_40"
          d="M65.0195 103.752L56.7962 101.201L43.5697 119.126L53.9647 119.124L65.0195 103.752Z"
          fill="url(#paint20_linear_15199_52304)"
        />
        <path
          id="Vector_41"
          d="M50.0745 83.8337H52.759"
          stroke="#673606"
          strokeWidth="3"
          stroke-linejoin="round"
        />
        <path
          id="Vector_42"
          d="M52.556 85.1811C53.3002 85.1811 53.9035 84.5778 53.9035 83.8336C53.9035 83.0894 53.3002 82.4861 52.556 82.4861C51.8118 82.4861 51.2085 83.0894 51.2085 83.8336C51.2085 84.5778 51.8118 85.1811 52.556 85.1811Z"
          fill="white"
        />
        <path
          id="Vector_43"
          d="M60.6918 24.2286C60.8172 24.3042 60.9174 24.4153 60.9797 24.5479C61.042 24.6805 61.0636 24.8286 61.0416 24.9734C61.0197 25.1183 60.9553 25.2534 60.8566 25.3616C60.7578 25.4698 60.6292 25.5463 60.487 25.5814L56.7735 26.4931C54.9833 34.4101 50.0693 36.8601 43.5173 36.8601C34.6675 36.8601 28.0805 30.0894 27.09 20.3594C26.061 10.2531 31.2113 2.25737 40.0628 2.25562C44.0055 2.25562 52.9638 2.25562 54.2745 13.4556C55.0235 19.8554 58.5095 22.9144 60.6918 24.2286Z"
          fill="url(#paint21_linear_15199_52304)"
        />
        <path
          id="Vector_44"
          d="M51.779 18.7896C51.779 20.1038 50.029 20.0566 47.117 20.0566C44.205 20.0566 41.853 20.1038 41.853 18.7896C41.853 17.4753 44.2102 15.2896 47.117 15.2896C50.0237 15.2896 51.779 17.4753 51.779 18.7896Z"
          fill="white"
        />
        <path
          id="Vector_45"
          d="M48.286 17.9968C48.2841 18.7561 48.004 19.4883 47.4985 20.0548H47.1205C44.212 20.0548 41.8565 20.1038 41.8565 18.7896C41.8565 17.5296 44.0195 15.4751 46.7565 15.3088C47.2183 15.5906 47.601 15.985 47.8685 16.4552C48.1361 16.9254 48.2797 17.4559 48.286 17.9968Z"
          fill="url(#paint22_linear_15199_52304)"
        />
        <path
          id="Vector_46"
          d="M47.6227 0.992166C48.0232 1.0022 48.4147 1.11241 48.7616 1.31273C49.1085 1.51305 49.3997 1.79709 49.6085 2.13891C49.8174 2.48072 49.9372 2.86942 49.9572 3.2695C49.9771 3.66957 49.8965 4.06826 49.7227 4.42916C47.8467 8.29491 43.029 14.5564 30.912 17.9584C29.7493 18.2838 28.6079 18.6806 27.4942 19.1467L34.0567 33.6857L15.3317 76.7532L20.86 12.4179C21.1329 9.24544 22.5876 6.29123 24.9359 4.14069C27.2841 1.99015 30.3545 0.800215 33.5387 0.806667C39.3802 0.815417 44.492 0.915166 47.6227 0.992166Z"
          fill="url(#paint23_linear_15199_52304)"
        />
        <path
          id="Vector_47"
          d="M27.4995 48.6411V28.9326L15.3317 76.7531L27.4995 48.6411Z"
          fill="url(#paint24_linear_15199_52304)"
        />
        <path
          id="Vector_48"
          d="M44.0615 1.01326L35.868 0.812012L28.168 18.487C28.168 18.487 42.819 9.56201 44.0615 1.01326Z"
          fill="url(#paint25_linear_15199_52304)"
        />
        <path
          id="Vector_49"
          d="M28.1698 27.7356C31.2925 27.7356 33.824 25.3405 33.824 22.3859C33.824 19.4313 31.2925 17.0361 28.1698 17.0361C25.047 17.0361 22.5155 19.4313 22.5155 22.3859C22.5155 25.3405 25.047 27.7356 28.1698 27.7356Z"
          fill="url(#paint26_linear_15199_52304)"
        />
        <path
          id="Vector_50"
          d="M27.097 20.7583L30.268 22.5223L27.097 24.0151"
          stroke="#673606"
          strokeWidth="3"
          strokeLinecap="round"
          stroke-linejoin="round"
        />
        <path
          id="Vector_51"
          d="M53.7023 27.4853C53.7023 27.4853 50.883 27.7251 50.0745 25.3416L48.8985 25.7703"
          stroke="#673606"
          strokeWidth="3"
          strokeLinecap="round"
          stroke-linejoin="round"
        />
        <path
          id="Vector_52"
          d="M51.499 71.1987H43.2285C42.6438 71.1987 42.1697 71.6727 42.1697 72.2575V76.7585C42.1697 77.3432 42.6438 77.8172 43.2285 77.8172H51.499C52.0837 77.8172 52.5577 77.3432 52.5577 76.7585V72.2575C52.5577 71.6727 52.0837 71.1987 51.499 71.1987Z"
          fill="white"
        />
        <path
          id="Vector_53"
          d="M47.3637 72.2558V70.0508"
          stroke="#69BDFF"
          strokeWidth="3"
          strokeMiterlimit="10"
        />
        <path
          id="Vector_54"
          d="M56.721 71.1986L56.8382 58.3116L32.3067 37.5374L25.3995 53.4939L56.721 71.1986Z"
          fill="url(#paint27_linear_15199_52304)"
        />
        <path
          id="Vector_55"
          d="M56.8383 58.3116L32.3068 37.5374L27.4995 48.6411H40.8328V58.3116H56.8383Z"
          fill="url(#paint28_linear_15199_52304)"
        />
      </g>
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_15199_52304"
        x1="163.251"
        y1="64.0604"
        x2="163.251"
        y2="69.5239"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.73" stopColor="#BDCF3F" />
        <stop offset="0.77" stopColor="#B7CA3C" />
        <stop offset="0.82" stopColor="#A7BC34" />
        <stop offset="0.88" stopColor="#8BA427" />
        <stop offset="0.94" stopColor="#668415" />
        <stop offset="1" stopColor="#3A5E00" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_15199_52304"
        x1="159.15"
        y1="55.8477"
        x2="160.127"
        y2="60.9472"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.07" stopColor="#41301B" />
        <stop offset="0.14" stopColor="#464136" />
        <stop offset="0.29" stopColor="#506672" />
        <stop offset="0.43" stopColor="#5985A5" />
        <stop offset="0.57" stopColor="#609DCC" />
        <stop offset="0.69" stopColor="#65AFE8" />
        <stop offset="0.8" stopColor="#68B9F9" />
        <stop offset="0.88" stopColor="#69BDFF" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_15199_52304"
        x1="142.1"
        y1="9.51123"
        x2="142.1"
        y2="22.7342"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.66" stopColor="#1B421E" />
        <stop offset="0.8" stopColor="#19401F" />
        <stop offset="0.88" stopColor="#123821" />
        <stop offset="0.94" stopColor="#072A25" />
        <stop offset="0.96" stopColor="#002227" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_15199_52304"
        x1="142.1"
        y1="5.18703"
        x2="142.1"
        y2="8.13578"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.28" stopColor="#B85F00" />
        <stop offset="0.57" stopColor="#B65E00" />
        <stop offset="0.69" stopColor="#AF5A00" />
        <stop offset="0.78" stopColor="#A35301" />
        <stop offset="0.85" stopColor="#914902" />
        <stop offset="0.91" stopColor="#7B3C03" />
        <stop offset="0.96" stopColor="#5F2D04" />
        <stop offset="1" stopColor="#471F05" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_15199_52304"
        x1="154.257"
        y1="9.01773"
        x2="154.257"
        y2="22.5802"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.62" stopColor="#FFC400" />
        <stop offset="0.81" stopColor="#FEC201" />
        <stop offset="0.88" stopColor="#FBBB02" />
        <stop offset="0.93" stopColor="#F5B006" />
        <stop offset="0.96" stopColor="#EC9F0A" />
        <stop offset="1" stopColor="#E08711" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_15199_52304"
        x1="154.257"
        y1="5.18703"
        x2="154.257"
        y2="8.13578"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.28" stopColor="#B85F00" />
        <stop offset="0.57" stopColor="#B65E00" />
        <stop offset="0.69" stopColor="#AF5A00" />
        <stop offset="0.78" stopColor="#A35301" />
        <stop offset="0.85" stopColor="#914902" />
        <stop offset="0.91" stopColor="#7B3C03" />
        <stop offset="0.96" stopColor="#5F2D04" />
        <stop offset="1" stopColor="#471F05" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_15199_52304"
        x1="166.413"
        y1="23.7632"
        x2="166.413"
        y2="9.52874"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.07" stopColor="#41301B" />
        <stop offset="0.08" stopColor="#4A321B" />
        <stop offset="0.12" stopColor="#6E3A1D" />
        <stop offset="0.16" stopColor="#8E421F" />
        <stop offset="0.21" stopColor="#AA4820" />
        <stop offset="0.26" stopColor="#C04D21" />
        <stop offset="0.33" stopColor="#D15122" />
        <stop offset="0.4" stopColor="#DC5423" />
        <stop offset="0.51" stopColor="#E35623" />
        <stop offset="0.82" stopColor="#E55623" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_15199_52304"
        x1="166.413"
        y1="5.18703"
        x2="166.413"
        y2="8.13578"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.28" stopColor="#B85F00" />
        <stop offset="0.57" stopColor="#B65E00" />
        <stop offset="0.69" stopColor="#AF5A00" />
        <stop offset="0.78" stopColor="#A35301" />
        <stop offset="0.85" stopColor="#914902" />
        <stop offset="0.91" stopColor="#7B3C03" />
        <stop offset="0.96" stopColor="#5F2D04" />
        <stop offset="1" stopColor="#471F05" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_15199_52304"
        x1="0.391998"
        y1="67.9069"
        x2="0.391998"
        y2="69.4749"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#41301B" />
        <stop offset="0.08" stopColor="#65381D" />
        <stop offset="0.19" stopColor="#8C411F" />
        <stop offset="0.31" stopColor="#AC4920" />
        <stop offset="0.42" stopColor="#C54F21" />
        <stop offset="0.54" stopColor="#D75322" />
        <stop offset="0.67" stopColor="#E25523" />
        <stop offset="0.82" stopColor="#E55623" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_15199_52304"
        x1="1.70099"
        y1="67.9069"
        x2="1.70099"
        y2="69.4749"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#41301B" />
        <stop offset="0.08" stopColor="#65381D" />
        <stop offset="0.19" stopColor="#8C411F" />
        <stop offset="0.31" stopColor="#AC4920" />
        <stop offset="0.42" stopColor="#C54F21" />
        <stop offset="0.54" stopColor="#D75322" />
        <stop offset="0.67" stopColor="#E25523" />
        <stop offset="0.82" stopColor="#E55623" />
      </linearGradient>
      <linearGradient
        id="paint10_linear_15199_52304"
        x1="112.924"
        y1="93.7178"
        x2="53.312"
        y2="96.8923"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.7" stopColor="#FFC4EA" />
        <stop offset="0.81" stopColor="#FEC2E8" />
        <stop offset="0.85" stopColor="#FABDE1" />
        <stop offset="0.88" stopColor="#F3B4D6" />
        <stop offset="0.9" stopColor="#E8A6C5" />
        <stop offset="0.92" stopColor="#DB94AF" />
        <stop offset="0.94" stopColor="#CB7F93" />
        <stop offset="0.95" stopColor="#B76573" />
        <stop offset="0.97" stopColor="#A1484F" />
        <stop offset="0.98" stopColor="#8C2B2B" />
      </linearGradient>
      <linearGradient
        id="paint11_linear_15199_52304"
        x1="45.8903"
        y1="45.1167"
        x2="30.8998"
        y2="39.9437"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.73" stopColor="#BDCF3F" />
        <stop offset="0.77" stopColor="#B7CA3C" />
        <stop offset="0.82" stopColor="#A7BC34" />
        <stop offset="0.88" stopColor="#8BA427" />
        <stop offset="0.94" stopColor="#668415" />
        <stop offset="1" stopColor="#3A5E00" />
      </linearGradient>
      <linearGradient
        id="paint12_linear_15199_52304"
        x1="57.078"
        y1="51.5146"
        x2="49.2537"
        y2="48.7391"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.3" stopColor="#FFC4EA" />
        <stop offset="0.51" stopColor="#FFC7EB" />
        <stop offset="0.68" stopColor="#FFD1EF" />
        <stop offset="0.83" stopColor="#FFE1F4" />
        <stop offset="0.97" stopColor="#FFF8FD" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <linearGradient
        id="paint13_linear_15199_52304"
        x1="63.7402"
        y1="78.3386"
        x2="71.2705"
        y2="81.1771"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFC4EA" />
        <stop offset="0.12" stopColor="#FCC0E5" />
        <stop offset="0.26" stopColor="#F3B4D6" />
        <stop offset="0.42" stopColor="#E4A0BD" />
        <stop offset="0.59" stopColor="#CF849A" />
        <stop offset="0.76" stopColor="#B4606D" />
        <stop offset="0.94" stopColor="#933437" />
        <stop offset="0.98" stopColor="#8C2B2B" />
      </linearGradient>
      <linearGradient
        id="paint14_linear_15199_52304"
        x1="91.1662"
        y1="62.4766"
        x2="111.295"
        y2="62.4766"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.22" stopColor="#FFC4EA" />
        <stop offset="0.53" stopColor="#CD8297" />
        <stop offset="0.85" stopColor="#9E444A" />
        <stop offset="1" stopColor="#8C2B2B" />
      </linearGradient>
      <linearGradient
        id="paint15_linear_15199_52304"
        x1="109.998"
        y1="54.1362"
        x2="102.734"
        y2="65.2067"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.28" stopColor="#B85F00" />
        <stop offset="0.57" stopColor="#B65E00" />
        <stop offset="0.69" stopColor="#AF5A00" />
        <stop offset="0.78" stopColor="#A35301" />
        <stop offset="0.85" stopColor="#914902" />
        <stop offset="0.91" stopColor="#7B3C03" />
        <stop offset="0.96" stopColor="#5F2D04" />
        <stop offset="1" stopColor="#471F05" />
      </linearGradient>
      <linearGradient
        id="paint16_linear_15199_52304"
        x1="100.256"
        y1="58.8033"
        x2="97.503"
        y2="64.3018"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#BDCF3F" />
        <stop offset="0.18" stopColor="#B9CC3D" />
        <stop offset="0.36" stopColor="#AEC238" />
        <stop offset="0.53" stopColor="#9AB12E" />
        <stop offset="0.7" stopColor="#7F9A21" />
        <stop offset="0.86" stopColor="#5D7C11" />
        <stop offset="1" stopColor="#3A5E00" />
      </linearGradient>
      <linearGradient
        id="paint17_linear_15199_52304"
        x1="50.5855"
        y1="84.7805"
        x2="27.5187"
        y2="97.6675"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.22" stopColor="#FFC4EA" />
        <stop offset="0.3" stopColor="#FABDE1" />
        <stop offset="0.43" stopColor="#ECAACA" />
        <stop offset="0.6" stopColor="#D48BA3" />
        <stop offset="0.79" stopColor="#B4606E" />
        <stop offset="1" stopColor="#8C2B2B" />
      </linearGradient>
      <linearGradient
        id="paint18_linear_15199_52304"
        x1="29.7062"
        y1="65.0929"
        x2="30.9155"
        y2="124.374"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.58" stopColor="#FFC4EA" />
        <stop offset="0.74" stopColor="#FEC2E8" />
        <stop offset="0.79" stopColor="#FABDE1" />
        <stop offset="0.83" stopColor="#F3B4D6" />
        <stop offset="0.87" stopColor="#E8A6C5" />
        <stop offset="0.89" stopColor="#DB94AF" />
        <stop offset="0.92" stopColor="#CB7F93" />
        <stop offset="0.94" stopColor="#B76573" />
        <stop offset="0.96" stopColor="#A1484F" />
        <stop offset="0.98" stopColor="#8C2B2B" />
      </linearGradient>
      <linearGradient
        id="paint19_linear_15199_52304"
        x1="73.2183"
        y1="119.674"
        x2="59.5018"
        y2="110.82"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.28" stopColor="#B85F00" />
        <stop offset="0.57" stopColor="#B65E00" />
        <stop offset="0.69" stopColor="#AF5A00" />
        <stop offset="0.78" stopColor="#A35301" />
        <stop offset="0.85" stopColor="#914902" />
        <stop offset="0.91" stopColor="#7B3C03" />
        <stop offset="0.96" stopColor="#5F2D04" />
        <stop offset="1" stopColor="#471F05" />
      </linearGradient>
      <linearGradient
        id="paint20_linear_15199_52304"
        x1="57.8445"
        y1="113.591"
        x2="50.6572"
        y2="109.235"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.42" stopColor="#BDCF3F" />
        <stop offset="0.53" stopColor="#BACC3D" />
        <stop offset="0.64" stopColor="#AFC338" />
        <stop offset="0.73" stopColor="#9EB430" />
        <stop offset="0.82" stopColor="#859F24" />
        <stop offset="0.91" stopColor="#658315" />
        <stop offset="0.99" stopColor="#3E6202" />
        <stop offset="1" stopColor="#3A5E00" />
      </linearGradient>
      <linearGradient
        id="paint21_linear_15199_52304"
        x1="53.5728"
        y1="33.3251"
        x2="30.9278"
        y2="6.03037"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.28" stopColor="#B85F00" />
        <stop offset="0.57" stopColor="#B65E00" />
        <stop offset="0.69" stopColor="#AF5A00" />
        <stop offset="0.78" stopColor="#A35301" />
        <stop offset="0.85" stopColor="#914902" />
        <stop offset="0.91" stopColor="#7B3C03" />
        <stop offset="0.96" stopColor="#5F2D04" />
        <stop offset="1" stopColor="#471F05" />
      </linearGradient>
      <linearGradient
        id="paint22_linear_15199_52304"
        x1="47.2938"
        y1="17.3581"
        x2="43.4333"
        y2="19.1886"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#38644B" />
        <stop offset="0.06" stopColor="#325943" />
        <stop offset="0.31" stopColor="#1C3326" />
        <stop offset="0.54" stopColor="#0D1711" />
        <stop offset="0.75" stopColor="#030604" />
        <stop offset="0.9" />
      </linearGradient>
      <linearGradient
        id="paint23_linear_15199_52304"
        x1="32.6515"
        y1="2.38867"
        x2="32.6515"
        y2="69.4329"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.35" stopColor="#FFC400" />
        <stop offset="0.51" stopColor="#FDC001" />
        <stop offset="0.68" stopColor="#F6B205" />
        <stop offset="0.86" stopColor="#EB9D0B" />
        <stop offset="1" stopColor="#E08711" />
      </linearGradient>
      <linearGradient
        id="paint24_linear_15199_52304"
        x1="20.6692"
        y1="25.4204"
        x2="21.3762"
        y2="51.4219"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.26" stopColor="#FFC400" />
        <stop offset="0.44" stopColor="#FDC001" />
        <stop offset="0.64" stopColor="#F6B205" />
        <stop offset="0.84" stopColor="#EB9D0B" />
        <stop offset="1" stopColor="#E08711" />
      </linearGradient>
      <linearGradient
        id="paint25_linear_15199_52304"
        x1="32.4957"
        y1="5.07151"
        x2="37.625"
        y2="11.6918"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.26" stopColor="#FFC400" />
        <stop offset="0.51" stopColor="#FEC101" />
        <stop offset="0.67" stopColor="#F9B903" />
        <stop offset="0.81" stopColor="#F2AA07" />
        <stop offset="0.94" stopColor="#E7950D" />
        <stop offset="1" stopColor="#E08711" />
      </linearGradient>
      <linearGradient
        id="paint26_linear_15199_52304"
        x1="28.588"
        y1="30.2609"
        x2="27.8705"
        y2="16.7666"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.28" stopColor="#B85F00" />
        <stop offset="0.57" stopColor="#B65E00" />
        <stop offset="0.69" stopColor="#AF5A00" />
        <stop offset="0.78" stopColor="#A35301" />
        <stop offset="0.85" stopColor="#914902" />
        <stop offset="0.91" stopColor="#7B3C03" />
        <stop offset="0.96" stopColor="#5F2D04" />
        <stop offset="1" stopColor="#471F05" />
      </linearGradient>
      <linearGradient
        id="paint27_linear_15199_52304"
        x1="38.9462"
        y1="64.3001"
        x2="46.746"
        y2="50.3859"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.09" stopColor="#FFC4EA" />
        <stop offset="0.25" stopColor="#FDC1E7" />
        <stop offset="0.38" stopColor="#F7B9DC" />
        <stop offset="0.5" stopColor="#ECABCB" />
        <stop offset="0.61" stopColor="#DE98B3" />
        <stop offset="0.72" stopColor="#CB7F94" />
        <stop offset="0.83" stopColor="#B4606D" />
        <stop offset="0.93" stopColor="#993C40" />
        <stop offset="0.98" stopColor="#8C2B2B" />
      </linearGradient>
      <linearGradient
        id="paint28_linear_15199_52304"
        x1="37.4693"
        y1="56.7051"
        x2="44.4115"
        y2="48.1249"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.58" stopColor="#FFC4EA" />
        <stop offset="0.68" stopColor="#FFC8EB" />
        <stop offset="0.79" stopColor="#FFD4F0" />
        <stop offset="0.89" stopColor="#FFE8F7" />
        <stop offset="0.98" stopColor="white" />
      </linearGradient>
    </defs>
  </svg>
);

export default LabIllustration;
