import { LabOrderDetailsDialog } from './order-details/LabOrderDetails';
import { CreateLabsOrderDialog } from './create-order/CreateLabsOrder';
import { LabOrderList } from './order-list/LabOrderList';
import { useLabs } from './LabsContext';
import ButtonType from '../../components/Button';
import { CreateLabsOrderProvider } from './create-order/LabsOrderContext';
import { usePatientInfoQuery } from '../patients/queries/patient-info.query';
import { useParams } from 'react-router-dom';

type EncounterLabsProps = {
  patientID?: string;
  isEncounterSigned: boolean;
};

const EncounterLabs = ({ isEncounterSigned }: EncounterLabsProps) => {
  const {
    selectedLabOrderID,
    closeLabOrderDetails,
    openLabsOrderCreationWizard,
    closeLabsOrderCreationWizard,
    isLabsOrderCreationWizardOpen,
  } = useLabs();

  const { patient } = useParams();

  const { patientInfo } = usePatientInfoQuery(patient);

  return (
    <section className="flex flex-col gap-4">
      <ButtonType
        disabled={isEncounterSigned || patientInfo?.status === 'archived'}
        classes="w-full mt-4"
        text="Order Labs"
        variant="contained"
        onClick={openLabsOrderCreationWizard}
      />
      <LabOrderList />
      <LabOrderDetailsDialog
        fullWidth
        open={Boolean(selectedLabOrderID)}
        onClose={() => closeLabOrderDetails()}
        handleClose={() => closeLabOrderDetails()}
        labOrderID={selectedLabOrderID}
      />
      <CreateLabsOrderProvider closeDialog={closeLabsOrderCreationWizard}>
        <CreateLabsOrderDialog
          fullWidth
          open={isLabsOrderCreationWizardOpen}
          onClose={closeLabsOrderCreationWizard}
          handleClose={closeLabsOrderCreationWizard}
        />
      </CreateLabsOrderProvider>
    </section>
  );
};

export default EncounterLabs;
