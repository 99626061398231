import { CreateAppointmentDTO } from '@aster/shared/dtos/appointment';
import { colors } from '../../theme';

const circle = (color: string) => {
  return (
    <span
      className={`h-[15px] w-[15px] rounded-full mr-2`}
      style={{ backgroundColor: color }}
    ></span>
  );
};

const AppointmentOptions: {
  text: string;
  value: CreateAppointmentDTO['type'];
}[] = [
  {
    text: 'Initial Visit',
    value: 'initial_visit',
  },
  {
    text: 'Prenatal',
    value: 'prenatal',
  },
  {
    text: 'Return OB',
    value: 'return_ob',
  },
  {
    text: 'Return GYN',
    value: 'return_gyn',
  },
  {
    text: 'Prob GYN',
    value: 'prob_gyn',
  },
  {
    text: 'Post Partum',
    value: 'post_partum',
  },
  {
    text: 'Block',
    value: 'block',
  },
  {
    text: 'General',
    value: 'general',
  },
  {
    text: 'Lacation',
    value: 'lactation',
  },
  {
    text: 'NewBorn Visit',
    value: 'newborn_visit',
  },
  {
    text: 'Labs Only',
    value: 'labs_only',
  },
  {
    text: 'Consultation',
    value: 'consultation',
  },
];

export default AppointmentOptions;
