import { AxiosError } from 'axios';
import axios from '../../../app/axiosConfig';
import { useQuery } from '@tanstack/react-query';

const validatePatientForLabs = async (patientID?: string) =>
  axios.get<void>(`/v2/labs/validate/patient/${patientID}`);

export function usePatientValidForLabOrders(patientID: string | undefined) {
  const {
    isLoading: isValidatingPatient,
    isError,
    error: validationError,
  } = useQuery({
    queryKey: ['isPatientValidForLabs', patientID],
    queryFn: () => validatePatientForLabs(patientID),
    enabled: patientID !== undefined,
    retry: (_, error: AxiosError) => {
      if (error.response?.status === 400) {
        return false;
      }

      return true;
    },
  });

  return { isValidatingPatient, isValidPatient: !isError, validationError };
}
