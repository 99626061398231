import forms from '../assets/empty-images/forms.webp';

const Forms = () => {
  return (
    <div className="flex flex-col justify-center items-center h-full w-full">
      <img src={forms} alt="Forms" width="481px" />
      <div className="flex flex-col items-center">
        <p className="text-h3 text-primary">Forms will be available soon</p>
        <p className="text-body text-gray-500">
          Access standard form templates and create custom forms.
        </p>
      </div>
    </div>
  );
};

export default Forms;
