import { Routes, Route, Outlet } from 'react-router-dom';
import Calendar from './pages/Calendar';
import Home from './pages/Home';
import Patients from './pages/Patients';
import PatientProfile from './features/patients/PatientProfile';
import Messages from './pages/Messages';
import Forms from './pages/Forms';
import Payments from './pages/Payments';
import Workflows from './pages/Workflows';
import Programs from './pages/Programs';
import Analytics from './pages/Analytics';
import Settings from './pages/Settings';
import Note from './pages/Note';
import SignOutRedirecter from './authentication/SignOutRedirecter';
import MembersPage from './pages/Members';
import PracticeDocuments from './features/documents/PracticeDocuments';
import EfaxPage from './pages/Efax';
import Prescriptions from './features/dosespot/Prescriptions';
import Error from './pages/Error';
import ErrorBoundary from './components/ErrorBoundary';
import PracticeSettings from './pages/PracticeSettings';

const Router = () => {
  // NOTE: Since this error boundary sits just a level above the other
  // set of routes, the `navigate` router event won't be able to reach
  // the destination route (`/`) for errors other than 404.
  //
  // To work around this, it instead does an `href` replacement, which
  // triggers a full navigation (with page load) back to the homepage.
  //
  // This, however, introduces a small -but noticeable- flicker due to
  // the browser having loading the page from scratch, instead of just
  // replacing the current DOM.
  //
  // In the future, we may revisit this approach, along with the route
  // definition strategy and improve the structure in order to support
  // route-level layouts, leverage the route's `errorElement` prop for
  // error handling & more.
  const ErrorWrapper = () => (
    <ErrorBoundary fallback={<Error type={500} />}>
      <Outlet />
    </ErrorBoundary>
  );

  return (
    <Routes>
      <Route element={<ErrorWrapper />}>
        <Route path="/" element={<Home />} />
        <Route path="/guest/form/:token" element={<SignOutRedirecter />} />
        <Route path="/guest/consent/:token" element={<SignOutRedirecter />} />
        <Route path="/signup" element={<SignOutRedirecter />} />
        <Route path="/calendar" element={<Calendar />} />
        <Route path="/patients" element={<Patients />} />
        <Route path="/patientProfile/:patient" element={<PatientProfile />} />
        <Route path="/encounter/:patient/:encounterId?" element={<Note />} />
        <Route path="/messages" element={<Messages />} />
        <Route path="/forms" element={<Forms />} />
        <Route path="/payments" element={<Payments />} />
        <Route path="/workflows" element={<Workflows />} />
        <Route path="/programs" element={<Programs />} />
        <Route path="/documents" element={<PracticeDocuments />} />
        <Route path="/analytics" element={<Analytics />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/prescription" element={<Prescriptions />} />
        <Route path="/members" element={<MembersPage />} />
        <Route path="/signin" element={<Home />} />
        <Route path="/practice" element={<PracticeSettings />} />
        <Route path="/efax" element={<EfaxPage />} />
        <Route path="*" element={<Error type={404} />}></Route>
      </Route>
    </Routes>
  );
};

export default Router;
