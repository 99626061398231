import { GetObjectCommand, HeadObjectCommand } from '@aws-sdk/client-s3';
import { getSignedUrl } from '@aws-sdk/s3-request-presigner';

import { getS3ClientWithCredentials } from './get-s3-client-with-credentials';

export const downloadFile = async (
  s3key: string,
  fileName: string,
  onDownload: (url: string) => void = () => {},
  shouldOpenInNew = true
) => {
  const s3 = await getS3ClientWithCredentials();

  if (!s3) return;

  const Bucket = import.meta.env.VITE_DOCUMENTS_BUCKET;
  const Key = s3key;
  const ResponseContentDisposition = 'attachment; filename ="' + fileName + '"';

  // Since the process of creating a signed URL doesn't account for
  // the file's existance, we first check if the object exists, and
  // then create the signed URL.
  //
  // This is especially useful when creating signed URLs for images
  // given that an signed URL could be passed as an `href` property
  // but the picture would be broken, as the URL leads to nothing.
  //
  // See: https://stackoverflow.com/a/56860362
  const objectExists = await s3
    .send(
      new HeadObjectCommand({
        Bucket,
        Key,
      })
    )
    .catch(() => {});

  if (!objectExists) return;

  const url = await getSignedUrl(
    s3,
    new GetObjectCommand({
      Bucket,
      Key,
      ResponseContentDisposition,
    }),
    { expiresIn: 180 }
  );

  if (!url) return;

  if (onDownload) {
    onDownload(url);
  }
  if (shouldOpenInNew) {
    window.open(url, '_blank');
  }

  return url;
};

export const getFileURL = async (s3key: string, fileName: string) => {
  const s3 = await getS3ClientWithCredentials();
  if (s3) {
    const url = await getSignedUrl(
      s3,
      new GetObjectCommand({
        Bucket: import.meta.env.VITE_DOCUMENTS_BUCKET,
        Key: s3key,
        ResponseContentDisposition: 'attachment; filename ="' + fileName + '"',
      }),
      { expiresIn: 60 }
    );
    return url;
  }
  throw new Error('Could not get file URL');
};
