import Card from '@mui/material/Card/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import { LabTestDTO } from '@aster/shared/dtos/labs';
import { customTwMerge } from '../../../utils';
import { useCreateLabsOrder } from './LabsOrderContext';
import Typography from '../../../components/Typography';
import OfficeBuilding from '../../../assets/icons/OfficeBuilding';
import { names } from '@aster/shared/utils/names';
import ExclamationCircle from '../../../assets/icons/ExclamationCircle';
import Tooltip from '@mui/material/Tooltip';
import BloodDrop from '../../../assets/icons/BloodDrop';
import SalivaTest from '../../../assets//icons/SalivaTest';
import UrineTest from '../../../assets/icons/UrineTest';

const getBadgeIcon = (type: LabTestDTO['sampleType']) => {
  switch (type) {
    case 'serum':
      return <BloodDrop />;
    case 'dried_blood_spot':
      return <BloodDrop />;
    case 'saliva':
      return <SalivaTest />;
    case 'urine':
      return <UrineTest />;
  }
};

const getBadgeBackground = (type: LabTestDTO['sampleType']) => {
  switch (type) {
    case 'serum':
      return 'bg-destructive-100';
    case 'dried_blood_spot':
      return 'bg-destructive-100';
    case 'saliva':
      return 'bg-success-100';
    case 'urine':
      return 'bg-warning-100';
  }
};

const getBadgeTextColor = (type: LabTestDTO['sampleType']) => {
  switch (type) {
    case 'serum':
      return 'text-red-500';
    case 'dried_blood_spot':
      return 'text-red-500';
    case 'saliva':
      return 'text-success-700';
    case 'urine':
      return 'text-warning-600';
  }
};

const TestTypeBadge = ({ labTest }: { labTest: LabTestDTO }) => {
  return (
    <div
      className={customTwMerge(
        getBadgeBackground(labTest.sampleType),
        getBadgeTextColor(labTest.sampleType),
        'ml-auto rounded-md w-24 h-5 flex justify-between items-center px-2'
      )}
    >
      <Typography
        text={`${
          labTest.sampleType ? names(labTest.sampleType).sentenceCase : '-'
        } sample`}
        variant="meta"
      />
      {getBadgeIcon(labTest.sampleType)}
    </div>
  );
};

export const TooltipInfo = ({ labTest }: { labTest: LabTestDTO }) => {
  return (
    <div>
      {labTest.description && (
        <Typography
          customClass="mb-2"
          text={labTest.description}
          variant="meta"
        />
      )}
      <Typography
        text={`Method: ${names(labTest.method ?? '-').sentenceCase}`}
        variant="meta"
      />

      <Typography
        customClass="mb-2"
        text={`Fasting: ${names(labTest.fasting ? 'yes' : 'no').sentenceCase}`}
        variant="meta"
      />

      <Typography text="Markers:" variant="meta" />
      <div className="flex flex-col">
        {labTest.markers?.map((marker) => (
          <Typography
            key={marker.id}
            text={`${marker.name}`}
            variant="meta"
            customClass="ml-2"
          />
        ))}
      </div>
    </div>
  );
};
export const LabTestCard = ({ labTest }: { labTest: LabTestDTO }) => {
  const { handleSelectLabTest, selectedLabTest } = useCreateLabsOrder();
  const isSelected = selectedLabTest?.id === labTest.id;
  return (
    <Card
      elevation={0}
      className={customTwMerge(
        'w-60 h-[6.5625rem] rounded-[0.625rem] cursor-pointer border border-grayBackground transition-all',
        isSelected && 'border-secondary border-2'
      )}
    >
      <CardActionArea
        onClick={() => handleSelectLabTest(labTest)}
        className="h-full"
      >
        <CardContent className="flex flex-col">
          <div className="flex justify-between">
            <Typography
              variant="bodySmall"
              text={labTest.name}
              customClass="font-semibold text-black overflow-ellipsis max-w-[80%] overflow-hidden whitespace-nowrap"
            />
            <Typography
              variant="meta"
              text={`$${labTest.price.toFixed(2)}`}
              customClass="font-semibold text-black"
            />
          </div>

          <Tooltip
            classes={{ tooltip: 'max-w-[238px]' }}
            title={<TooltipInfo labTest={labTest} />}
          >
            <div className="mb-5 flex items-center gap-2">
              <Typography text="More details" variant="meta" />
              <ExclamationCircle
                height="0.8rem"
                width="0.8rem"
                className="fill-asterGray"
              />
            </div>
          </Tooltip>

          <div className="mt-auto flex items-center">
            <OfficeBuilding />
            <Typography
              variant="meta"
              text={labTest.labName ?? ''}
              customClass="text-asterGray ml-1"
            />
            <TestTypeBadge labTest={labTest} />
          </div>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
