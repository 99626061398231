import React, {
  forwardRef,
  HTMLAttributes,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { createPortal } from 'react-dom';

import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { cn } from '../utils';
import SelectDropdown from './SelectDropdown';

type ReadonlyTableRecord = {
  [key: string]: any;
};

export type TableColumnConfig<T> = {
  [K in keyof T]?: {
    title: string;
    className?: string;
  } & (T[K] extends React.ReactNode
    ? { render?: (value: T[K]) => React.ReactNode }
    : { render: (value: T[K]) => React.ReactNode });
};

export type ReadonlyTableProps<T> = HTMLAttributes<HTMLTableElement> & {
  data: T[];
  config: TableColumnConfig<T>;
  pagination?: boolean;
  paginationNode?: Element | null;
};

export const ReadonlyTable = <T extends ReadonlyTableRecord>({
  id,
  className,
  data,
  config,
  pagination = false,
  paginationNode = null,
  ...rest
}: ReadonlyTableProps<T>) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(
    pagination ? 10 : Number.POSITIVE_INFINITY
  );

  const items = useMemo(
    () =>
      data.slice(
        itemsPerPage * currentPage,
        itemsPerPage * currentPage + itemsPerPage
      ),
    [currentPage, data, itemsPerPage]
  );

  const pages = useMemo(
    () => Math.max(Math.ceil(data.length / itemsPerPage), 1),
    [data.length, itemsPerPage]
  );

  const updateItemsPerPage = useCallback(
    (itemsPerPage: number) => {
      setItemsPerPage(itemsPerPage);

      const newPages = Math.max(Math.ceil(data.length / itemsPerPage), 1);

      if (currentPage > newPages - 1) {
        setCurrentPage(newPages - 1);
      }
    },
    [currentPage, data.length]
  );

  const pageSelectionOptions = [
    { text: '5', value: '5' },
    { text: '10', value: '10' },
    { text: '25', value: '25' },
    { text: '50', value: '50' },
  ];

  return (
    <>
      <table id={id} className={className} {...rest}>
        <thead>
          <tr className="border-b border-graySeparator">
            {Object.values(config).map((options) => (
              <th
                className={cn(
                  'text-[14px] p-0 pb-2.5 min-w-[104px] text-left font-semibold',
                  options.className
                )}
              >
                {options.title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="group">
          {!items.length ? (
            <td
              colSpan={Object.keys(config).length}
              className="text-center text-gray text-[14px] w-full pt-2.5"
            >
              No data
            </td>
          ) : (
            items.map((row, i) => (
              <tr className="border-b border-b-graySeparator last:border-none">
                {Object.keys(config).map((field) => (
                  <td
                    className={cn('p-0 py-2.5  text-[14px] text-black', {
                      'pb-0': i === items.length - 1,
                    })}
                  >
                    {config[field]?.render?.(row[field]) ?? row[field] ?? ''}
                  </td>
                ))}
              </tr>
            ))
          )}
        </tbody>
      </table>
      {pagination &&
        paginationNode &&
        createPortal(
          <div className="text-right text-gray-500 text-[14px] space-x-8">
            <span>
              Rows per page:{' '}
              <SelectDropdown
                classes="[&_.MuiOutlinedInput-notchedOutline]:!border-none [&_.MuiSelect-select]:p-0 [&_.MuiOutlinedInput-input]:text-[14px] [&_.MuiOutlinedInput-input]:text-gray-500 inline ml-[0.5ch]"
                value={itemsPerPage.toString()}
                options={pageSelectionOptions}
                handleChange={(evt: any) =>
                  updateItemsPerPage(Number(evt.target.value))
                }
              />
            </span>
            <span>
              Page {currentPage + 1} of {pages}
            </span>
            <button
              className="disabled:opacity-50"
              onClick={() =>
                setCurrentPage((currentPage) => Math.max(currentPage - 1, 0))
              }
              disabled={currentPage - 1 < 0}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </button>
            <button
              className="disabled:opacity-50"
              onClick={() =>
                setCurrentPage((currentPage) =>
                  Math.min(currentPage + 1, pages - 1)
                )
              }
              disabled={currentPage + 1 > pages - 1}
            >
              <FontAwesomeIcon icon={faChevronRight} />
            </button>
          </div>,
          paginationNode
        )}
    </>
  );
};

ReadonlyTable.usePagination = () => {
  const [paginationNode, setPaginationNode] = useState<HTMLDivElement | null>(
    null
  );

  return {
    node: paginationNode,
    ref: setPaginationNode,
  };
};

ReadonlyTable.PaginationControls = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement>
>((props, ref: React.ForwardedRef<HTMLDivElement>) => {
  return <div ref={ref} {...props} />;
});
