import { cn } from '../../utils';

export type AmountProps = {
  value: number;
  type: 'charge' | 'payment' | 'adjustment' | 'owed';
};

const USDollar = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const Amount = ({ value, type }: AmountProps) => {
  const classes = cn('font-semibold inline-flex items-center', {
    'text-green-500': type === 'payment',
    'text-blue-500': type === 'charge',
    'text-red-500': type === 'owed',
    'text-gray-500': type === 'adjustment',
  });
  const formattedValue = USDollar.format(value);

  if (type === 'adjustment') {
    const sign = { [-1]: '-', 0: '', 1: '+' }[Math.sign(value)];

    return (
      <span className={classes}>
        {sign}({USDollar.format(Math.abs(value))})
      </span>
    );
  }

  return <span className={classes}>{formattedValue}</span>;
};
