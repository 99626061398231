import { Delete, Download, MoreHoriz } from '@mui/icons-material';
import { IconButton, Menu, MenuItem } from '@mui/material';
import React, { useState } from 'react';
import { colors } from '../../theme';
import Typography from '../../components/Typography';

interface DocumentOptionsMenuProps {
  id: string;
  deleteFile: any;
  downloadFile: (s3key: string, fileName: string) => Promise<string | void>;
  s3key: string;
  fileName: string;
  readonly: boolean;
}

const DocumentOptionsMenu = ({
  id,
  deleteFile,
  downloadFile,
  s3key,
  fileName,
  readonly,
}: DocumentOptionsMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreHoriz />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={() => downloadFile(s3key, fileName)}>
          <Download sx={{ color: colors.gray }} />
          <Typography
            variant="bodySmall"
            customClass="text-gray-500 ml-2"
            text="Download"
          />
        </MenuItem>
        <MenuItem disabled={readonly} onClick={() => deleteFile(id)}>
          <Delete sx={{ color: colors.gray }} />
          <Typography
            variant="bodySmall"
            customClass="text-gray-500 ml-2"
            text="Delete"
          />
        </MenuItem>
      </Menu>
    </div>
  );
};

export default DocumentOptionsMenu;
