import Divider from '@mui/material/Divider/Divider';
import CollapsibleComponent from '../../../components/CollapsibleComponent';
import Typography from '../../../components/Typography';
import { CircularProgress } from '@mui/material';
import { useLabs } from '../LabsContext';
import LabTestCard from './LabTestCard';

export function LabOrderList() {
  const {
    isLoadingOrders,
    pendingLabOrders,
    labOrdersWithResults,
    labOrders,
    openLabOrderDetails,
    newOrderIDs,
  } = useLabs();
  return (
    <>
      <CollapsibleComponent label="Pending tests">
        <div className="flex flex-col justify-center items-center pl-5 py-3 gap-4">
          {isLoadingOrders && <CircularProgress size="1.5rem" />}
          {!isLoadingOrders && labOrders?.length === 0 && (
            <Typography
              customClass="text-center my-3"
              variant="body"
              text="No pending tests"
            />
          )}
          {labOrders &&
            pendingLabOrders?.map((order) => (
              <LabTestCard
                isNew={newOrderIDs.includes(order.id)}
                key={order.id}
                labOrder={order}
                showDetails={() => openLabOrderDetails(order.id)}
              />
            ))}
        </div>
      </CollapsibleComponent>
      <Divider />
      <CollapsibleComponent label="Test results">
        <div className="flex flex-col justify-center items-center pl-5 py-3 gap-4">
          {isLoadingOrders && <CircularProgress size="1.5rem" />}
          {!isLoadingOrders && labOrders?.length === 0 && (
            <Typography
              customClass="text-center my-3"
              variant="body"
              text="No results available"
            />
          )}
          {labOrders &&
            labOrdersWithResults?.map((order) => (
              <LabTestCard
                key={order.id}
                labOrder={order}
                showDetails={() => openLabOrderDetails(order.id)}
              />
            ))}
        </div>
      </CollapsibleComponent>
    </>
  );
}
