import { customTwMerge } from '../../utils';

const GreenCheckMark = ({ classes }: { classes?: string }) => (
  <svg
    className={customTwMerge(classes)}
    width="28"
    height="28"
    viewBox="0 0 12 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.9375 6.5C11.9375 9.71094 9.3125 12.3125 6.125 12.3125C2.91406 12.3125 0.3125 9.71094 0.3125 6.5C0.3125 3.3125 2.91406 0.6875 6.125 0.6875C9.3125 0.6875 11.9375 3.3125 11.9375 6.5ZM5.44531 9.59375L9.75781 5.28125C9.89844 5.14062 9.89844 4.88281 9.75781 4.74219L9.21875 4.22656C9.07812 4.0625 8.84375 4.0625 8.70312 4.22656L5.1875 7.74219L3.52344 6.10156C3.38281 5.9375 3.14844 5.9375 3.00781 6.10156L2.46875 6.61719C2.32812 6.75781 2.32812 7.01562 2.46875 7.15625L4.90625 9.59375C5.04688 9.73438 5.30469 9.73438 5.44531 9.59375Z"
      fill="#09BC8A"
    />
  </svg>
);

export default GreenCheckMark;
