import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import ButtonType from '../../../components/Button';
import SelectDropdown from '../../../components/SelectDropdown';
import BasicTextfield from '../../../components/Textfield';
import { Option } from '../../../components/Autocomplete';
import AppointmentOptions from '../AppointmentOptions';
import { InviteeWithColor, NewEvent } from '../types';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { SelectChangeEvent } from '@mui/material/Select';
import { EventImpl } from '@fullcalendar/core/internal';
import SelectInvitees from './SelectInvitees';
import CalendarDatePicker from './CalendarDatePicker';
import Typography from '../../../../src/components/Typography';
import { StyledSwitchToggle } from '../../../../src/components/ToggleButton';
import { encounterOptions } from '../../notes/constants';
import { TemplateType } from '@aster/shared/dtos/encounter';
import { CreateAppointmentDTO } from '@aster/shared/dtos/appointment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { motion } from 'framer-motion';

type ModalProps = {
  open: boolean;
  loading?: boolean;
  eventInfo: NewEvent;
  handleConfirm: (
    e: Partial<EventImpl>,
    encounterData?: {
      patientID: string;
      templateType: TemplateType;
    }
  ) => void;
  handleCancel?: () => void;
  handleClose: () => void;
  staffColorMap: Record<string, InviteeWithColor>;
};

const CreateApptModal = (props: ModalProps) => {
  const {
    open,
    handleClose,
    handleConfirm,
    eventInfo,
    staffColorMap,
    loading,
  } = props;
  const [invitedPatients, setInvitedPatients] = useState<Option[]>([]);
  const [invitedStaff, setInvitedStaff] = useState<Option[]>([]);
  const [appointmentType, setAppointmentType] = useState<
    CreateAppointmentDTO['type']
  >(AppointmentOptions[0].value);
  const [note, setNote] = useState('');
  const [startDate, setStartDate] = useState(() =>
    eventInfo?.start ? eventInfo.start : dayjs().set('hour', 7).set('minute', 0)
  );
  const [endDate, setEndDate] = useState(() =>
    eventInfo?.end ? eventInfo.end : dayjs().set('hour', 7).set('minute', 30)
  );
  const [createEncounter, setCreateEncounter] = useState(false);
  const [encounterType, setEncounterType] = useState<TemplateType>(
    encounterOptions[0].value as TemplateType
  );

  const disableButton = !appointmentType;

  useEffect(() => {
    if (eventInfo) {
      setStartDate(dayjs(eventInfo.start));
      setEndDate(dayjs(eventInfo.end));
      setInvitedPatients([]);
      setInvitedStaff([]);
    }
  }, [eventInfo]);

  const handleApptChange = (event: SelectChangeEvent) => {
    setAppointmentType(event.target.value as CreateAppointmentDTO['type']);
  };

  const prepareInfo = () => {
    const start = startDate;
    const end = endDate;

    const apptInfo: Partial<EventImpl> & Record<string, unknown> = {
      invitedPatientIDs: invitedPatients.map((option) => option.id),
      invitedStaffIDs: invitedStaff.map((option) => option.id),
      start: start.toDate(),
      end: end.toDate(),
      note,
      telehealth: false,
      type: appointmentType,
    };

    const encounterData =
      invitedPatients.length > 0 && createEncounter
        ? {
            patientID: invitedPatients[0]?.id,
            templateType: encounterType,
          }
        : undefined;
    handleConfirm(apptInfo, encounterData);
    setInvitedPatients([]);
    setInvitedStaff([]);
    setNote('');
    setAppointmentType(AppointmentOptions[0].value);
  };

  return (
    <Dialog
      open={open}
      keepMounted
      fullWidth
      maxWidth={'sm'}
      onClose={() => {
        handleClose();
        setInvitedPatients([]);
        setNote('');
        setAppointmentType(AppointmentOptions[0].value);
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent className="bg-white w-full flex flex-col items-center p-6">
        <Typography
          variant="h5"
          customClass="font-semibold self-start"
          text={'New Appointment'}
        />
        <div className="flex flex-col gap-4 w-full h-auto mt-4">
          <CalendarDatePicker
            startDate={startDate}
            onStartDateChange={setStartDate}
            endDate={endDate}
            onEndDateChange={setEndDate}
            isEdit={false}
          />
          <SelectInvitees
            invitedPatients={invitedPatients}
            setInvitedPatients={setInvitedPatients}
            invitedStaff={invitedStaff}
            setInvitedStaff={setInvitedStaff}
            staffColorMap={staffColorMap}
          />
          <SelectDropdown
            IconComponent={() => (
              <FontAwesomeIcon
                style={{ fontSize: 12, marginRight: 19 }}
                icon={faAngleDown}
              />
            )}
            required
            options={AppointmentOptions}
            handleChange={handleApptChange}
            value={appointmentType}
            label="Appointment type"
          />

          <BasicTextfield
            id="1"
            variant="filled"
            label="Short note..."
            rows={4}
            value={note}
            multiline
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setNote(event.target.value);
            }}
          />
          {invitedPatients.length > 0 && (
            <div
              className="flex items-center self-start cursor-pointer"
              onClick={() => setCreateEncounter(!createEncounter)}
            >
              <StyledSwitchToggle checked={createEncounter} />
              <Typography
                variant={'body'}
                text={'Create encounter note for this appointment?'}
                customClass="ml-2"
              />
            </div>
          )}
          {createEncounter && (
            <motion.div
              initial={{ height: 0 }}
              animate={{ height: createEncounter ? 'auto' : 0 }}
              transition={{ duration: 0.1, ease: 'easeInOut' }}
              className="overflow-hidden"
            >
              <SelectDropdown
                handleChange={(e: SelectChangeEvent) => {
                  setEncounterType(e.target.value as TemplateType);
                }}
                IconComponent={() => (
                  <FontAwesomeIcon
                    style={{ fontSize: 12, marginRight: 20 }}
                    icon={faAngleDown}
                  />
                )}
                classes="[&_.MuiOutlinedInput-notchedOutline]:rounded-[5px]"
                label=""
                options={encounterOptions}
                value={encounterType}
              />
            </motion.div>
          )}
        </div>
      </DialogContent>

      <DialogActions className=" bg-white flex !pt-0">
        <ButtonType
          variant="contained"
          onClick={prepareInfo}
          text="Save"
          classes={'bg-asterGreen-900 rounded-[5px] h-[28px] px-6'}
          disabled={disableButton}
        />
      </DialogActions>
    </Dialog>
  );
};

export default CreateApptModal;
