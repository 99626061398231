import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { colors } from '../theme';
import Asterisk from '../assets/icons/Asterisk';
import { twMerge } from 'tailwind-merge';

type RadioProperties<T> = {
  value: T | null;
  label?: string;
  options: object[];
  width?: string;
  required?: boolean;
  handleChange: any;
  classes?: string;
  disabled?: boolean;
};

function RadioOptions<T>(props: RadioProperties<T>) {
  const {
    value,
    label,
    options,
    width,
    required,
    handleChange,
    classes,
    disabled,
  } = props;
  return (
    <FormControl className={twMerge(`w-[${width}]`, classes)}>
      <div className="grid grid-cols-12">
        <div className="col-span-11 text-body">
          <FormLabel className="text-gray-500 font-semibold">{label}</FormLabel>
        </div>

        {required && (
          <div className="col-span-1 text-h5 ml-1">
            <Asterisk />
          </div>
        )}
      </div>
      <RadioGroup
        name="radio-buttons-group"
        aria-labelledby="radio-buttons-group"
        row
        value={value}
        onChange={handleChange}
      >
        {options.map((item: any) => (
          <FormControlLabel
            key={item.key}
            value={item.value}
            sx={{ color: colors.gray }}
            control={
              <Radio
                sx={{
                  color: colors.gray,
                  '&.Mui-checked': {
                    color: colors.green,
                  },
                }}
                disabled={disabled}
              />
            }
            label={item.text}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}

export default RadioOptions;
