import axios from '../../../app/axiosConfig';
import { LabsOrderDTO } from '@aster/shared/dtos/labs';
import { useQuery } from '@tanstack/react-query';

export function usePatientLabOrders(patientID: string | undefined) {
  const fetchPatientLabOrders = async () => {
    const response = await axios.get<LabsOrderDTO[]>(
      `/v2/labs/orders/patient/${patientID}`
    );
    return response.data;
  };

  const { data: labOrders, isLoading: isLoadingOrders } = useQuery({
    queryKey: ['patientLabOrders', patientID],
    queryFn: fetchPatientLabOrders,

    enabled: patientID !== undefined,
  });

  return { labOrders, isLoadingOrders };
}
