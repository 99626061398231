import { useCreateLabsOrder } from './LabsOrderContext';
import Typography from '../../../components/Typography';
import LabTestMarkerQuestion from './LabTestMarkerQuestion';

export default function LabTestMarkerQuestions() {
  const { labTestQuestions, onAnswerChange } = useCreateLabsOrder();

  return (
    <section className="flex flex-col p-5 overflow-hidden">
      <Typography
        text="To finalize this test please confirm the following information"
        variant="h6"
        customClass="mb-4"
      />
      <ul className="flex flex-col gap-2 pr-4 overflow-auto">
        {labTestQuestions?.map((question) => (
          <li>
            <LabTestMarkerQuestion
              key={question.id}
              question={question}
              handleAnswerChange={onAnswerChange}
            />
          </li>
        ))}
      </ul>
    </section>
  );
}
