import { HTMLAttributes } from 'react';
import { useDropzone } from 'react-dropzone';

import { CircularProgress, InputLabel } from '@mui/material';

import Typography from './Typography';

import { cn } from '../utils';

type PictureFieldProps = Partial<
  Omit<HTMLAttributes<HTMLInputElement>, 'onDrop'>
> & {
  label: string;
  variant: 'rounded' | 'squared';
  displayPictureUrl?: string | null;
  displayPictureAlt?: string | null;
  onDrop?: (picture: File) => void;
  isLoading?: boolean;
};

export const PictureField = ({
  label,
  variant,
  className,
  displayPictureUrl,
  displayPictureAlt,
  onDrop: onPictureDrop,
  isLoading = false,
  ...rest
}: PictureFieldProps) => {
  const classNames = cn(
    'p-6 w-auto  mx-auto content-center text-center overflow-hidden',
    className,
    {
      'border border-grayBlock bg-grayBackground border-dashed':
        !displayPictureUrl,
      'h-[203px] aspect-square rounded-full': variant === 'rounded',
      'h-[90px] rounded-[8px]': variant === 'squared',
    }
  );

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/png': [],
      'image/jpg': [],
      'image/jpeg': [],
    },
    onDrop: ([picture]) => {
      onPictureDrop?.(picture);
    },
  });

  return (
    <div className="space-y-1">
      <InputLabel className="text-gray-500 text-bodySmall !mb-0">
        {label}
      </InputLabel>
      <div
        {...getRootProps({
          className: cn('relative group cursor-pointer', classNames),
        })}
      >
        <input {...getInputProps()} {...rest} />
        {isLoading ? (
          <CircularProgress />
        ) : displayPictureUrl ? (
          <>
            <div
              className="bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 absolute top-0 left-0 w-full h-full content-center text-center z-10 pointer-events-none transition-opacity"
              id="overlay"
            >
              <Typography
                variant="bodyMedium"
                customClass="text-white font-semibold"
              >
                Click to replace
              </Typography>
            </div>
            <div className="absolute top-0 left-0 w-full h-full text-center">
              <img
                src={displayPictureUrl ?? ''}
                alt={displayPictureAlt ?? ''}
                className="h-full mx-auto"
              />
            </div>
          </>
        ) : (
          <Typography variant="bodyMedium">
            Drag and drop an image,
            <br />
            or <span className="underline">Browse</span>
          </Typography>
        )}
      </div>
    </div>
  );
};
