import Dialog, { DialogProps } from '@mui/material/Dialog';
import ButtonType from '../../../components/Button';
import { useCreateLabsOrder } from './LabsOrderContext';
import Typography from '../../../components/Typography';
import poweredByVital from '../../../assets/powered-by-vital.webp';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { PatientInfo } from './PatientInfo';
import { OrderCheckout } from './OrderCheckout';
import { SelectOrder } from './SelectOrder';
import { useParams } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import UpdatePatientInfo from './UpdatePatientInfo';
import useMediaQuery from '@mui/material/useMediaQuery';
import useTheme from '@mui/material/styles/useTheme';
import { customTwMerge } from '../../../utils';
import LabTestMarkerQuestions from './LabTestMarkerQuestions';
import { useLabs } from '../LabsContext';

const CreateLabsOrder = () => {
  const { patient } = useParams();
  const { isValidPatient, isValidatingPatient } = useCreateLabsOrder();

  const { isCheckoutQuestionsVisible } = useLabs();

  if (isValidatingPatient) {
    return (
      <div className="grid place-content-center flex-1">
        <CircularProgress />
      </div>
    );
  }

  if (!isValidPatient && patient) {
    return <UpdatePatientInfo patientID={patient} />;
  }

  return (
    <section className="flex-1 grid grid-cols-fill-first border-t border-grayBackground overflow-hidden">
      {isCheckoutQuestionsVisible ? (
        <LabTestMarkerQuestions />
      ) : (
        <SelectOrder />
      )}
      <div className="flex-1 px-4 flex flex-col justify-between border-l border-grayBackground">
        <div className="flex-1 flex flex-col">
          <Typography customClass="my-4" variant="h6" text="Draft Order" />
          <PatientInfo />
          <Typography
            customClass="mt-2 max-w-[36ch] text-center mx-auto text-asterGray"
            variant="meta"
            text="Please review contact information. Updates can be made in the patient’s profile."
          />
        </div>
        <OrderCheckout />
      </div>
    </section>
  );
};

export function CreateLabsOrderDialog({
  handleClose,
  ...props
}: DialogProps & { handleClose: () => void }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { isValidPatient, isValidatingPatient } = useCreateLabsOrder();
  const isValidateFormStep = !isValidPatient || isValidatingPatient;

  const title = () => {
    if (isValidatingPatient) {
      return 'Validating Patient Information';
    }
    if (isValidPatient) {
      return 'New Order';
    }
    return 'Update Patient Information';
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      maxWidth={isValidateFormStep ? 'xs' : 'md'}
      onClose={handleClose}
      {...props}
    >
      <div
        className={customTwMerge(
          'flex items-center justify-between',
          isValidateFormStep && 'justify-center'
        )}
      >
        <DialogTitle
          className={customTwMerge(
            'text-h4 font-semibold',
            isValidateFormStep && 'pb-2 pt-8'
          )}
        >
          {title()}
        </DialogTitle>
        {!isValidateFormStep && (
          <DialogActions className="flex items-center gap-4">
            <a
              href="https://www.tryvital.com/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={poweredByVital} alt="Powered by Vital" />
            </a>
            <ButtonType
              className="px-8"
              onClick={handleClose}
              variant="contained"
              text="Close"
            />
          </DialogActions>
        )}
      </div>
      <DialogContent className="min-h-[34rem] flex flex-col p-0 pb-4">
        <CreateLabsOrder />
      </DialogContent>
    </Dialog>
  );
}
