import { Children, HTMLAttributes, useState } from 'react';

import { cn } from '../../../../../utils';

export type ExpandableGridProps = HTMLAttributes<HTMLDivElement> & {
  cols: number;
  limit: number;
  buttonText: string;
};

export const ExpandableGrid = ({
  cols,
  limit,
  buttonText,
  className,
  children,
  ...rest
}: ExpandableGridProps) => {
  const [isOpen, setIsOpen] = useState(Children.count(children) <= limit);

  const classNames = cn(
    `grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3  gap-5`,
    {},
    className
  );

  return (
    <div>
      <div className={classNames} {...rest}>
        {Children.map(children, (child, i) => {
          if (!isOpen && i > limit - 1) return null;

          return child;
        })}
      </div>
      {!isOpen && (
        <div className="mt-6">
          <div className="w-fit mx-auto space-y-2.5">
            <hr className="border-[0.5px] border-grayBorder" />
            <button
              className="px-5 py-3 text-asterMain font-semibold"
              onClick={() => setIsOpen(true)}
            >
              {buttonText}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
