import DynamicCheckboxes from '../components/DynamicCheckboxes';
import CollapsibleText from '../../../components/CollapsibleText';
import Vitals from './Vitals';
import { useEffect, useState } from 'react';
import Tag from '../../../components/Tag';
import { colorPerInitial } from '../../../utils';
import CircularProgress from '@mui/material/CircularProgress';
import {
  PatientProfileForEncounterDTO,
  UpdatePatientProfileDTO,
} from '@aster/shared/dtos/patient';
import { usePatientProfileQuery } from '../queries/patient-profile.query';
import { useUpdatePatientProfileMutation } from '../../patients/mutations/update-patient-profile.mutation';
import { usePatientSnapshotQuery } from '../queries/patient-snapshot.query';
import { isPatientReadonly } from '../../patients/utils/is-patient-readonly';

export type PrechartProps = {
  patientID?: string;
  encounterID: string;
  isEncounterSigned: boolean;
  enforceSaveMissing: boolean;
  handleUnsaved: (isDirty: boolean) => void;
  handleNote?: (note: string) => void;
};

const Problems = ({
  patientProfile,
}: {
  patientProfile?: PatientProfileForEncounterDTO;
}) => {
  return patientProfile?.problemList.map((item) => (
    <div key={item.abbr} className="flex gap-2 items-center mb-2">
      <Tag tagText={item.abbr} color={colorPerInitial(item.abbr.charAt(0))} />
      <p>{item.name}</p>
    </div>
  ));
};

const Prechart = ({
  patientID,
  isEncounterSigned,
  enforceSaveMissing,
  handleUnsaved,
  handleNote,
  encounterID,
}: PrechartProps) => {
  const [state, setState] = useState({
    additionalproblem: '',
    important: '',
    prechartnotes: '',
    vaccinationnotes: '',
    medicationnotes: '',
  });

  const { patientProfile, isPatientProfileLoading } = usePatientProfileQuery(
    patientID as string
  );
  const { patientSnapshot, isPatientSnapshotLoading } =
    usePatientSnapshotQuery(encounterID);

  const { updatePatientProfileMutation } = useUpdatePatientProfileMutation(
    patientID as string,
    {
      onSuccess: () => {
        handleUnsaved(false);
      },
      onMutate: () => {
        handleUnsaved(true);
      },
    }
  );

  const saveInfo = () => {
    const info = {
      additionalProblems: state.additionalproblem,
      importantNotes: state.important,
      preChartNotes: state.prechartnotes,
      vaccinationNotes: state.vaccinationnotes,
      medicationNotes: state.medicationnotes,
      checklist: {
        ...patientProfile?.checklist,
      },
    } satisfies UpdatePatientProfileDTO;
    updatePatientProfileMutation.mutate(info);
  };

  useEffect(() => {
    if (isEncounterSigned && patientSnapshot) {
      setState({
        additionalproblem: patientSnapshot.additionalProblems ?? '',
        important: patientSnapshot.importantNotes ?? '',
        prechartnotes: patientSnapshot.preChartNotes ?? '',
        vaccinationnotes: patientSnapshot.vaccinationNotes ?? '',
        medicationnotes: patientSnapshot.medicationNotes ?? '',
      });
    } else if (patientProfile) {
      setState({
        additionalproblem: patientProfile.additionalProblems ?? '',
        important: patientProfile.importantNotes ?? '',
        prechartnotes: patientProfile.preChartNotes ?? '',
        vaccinationnotes: patientProfile.vaccinationNotes ?? '',
        medicationnotes: patientProfile.medicationNotes ?? '',
      });
    }
  }, [patientProfile, patientSnapshot, isEncounterSigned]);

  const handleFormInput = (event: any) => {
    handleUnsaved(true);
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  };

  if (
    isPatientProfileLoading ||
    !patientProfile ||
    (isEncounterSigned && (isPatientSnapshotLoading || !patientSnapshot))
  ) {
    return (
      <div className="flex flex-col justify-center items-center h-full w-[400px]">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-[15px]">
      <Vitals
        disabled={isEncounterSigned || isPatientReadonly(patientProfile)}
        enforceSaveMissing={enforceSaveMissing}
        handleUnsaved={handleUnsaved}
        handleNote={handleNote}
      />
      <div>
        <CollapsibleText
          label="Problem list"
          textValue={state.additionalproblem}
          name="additionalproblem"
          rows={2}
          handleChange={handleFormInput}
          handleBlur={saveInfo}
          extraComponent={<Problems />}
          disabled={isEncounterSigned || isPatientReadonly(patientProfile)}
        />
      </div>
      <DynamicCheckboxes
        data={
          isEncounterSigned
            ? (patientSnapshot as PatientProfileForEncounterDTO)
            : patientProfile
        }
        disabled={isEncounterSigned || isPatientReadonly(patientProfile)}
      />
      <div>
        <CollapsibleText
          label="Important"
          textValue={state.important}
          name="important"
          rows={2}
          handleChange={handleFormInput}
          handleBlur={saveInfo}
          disabled={isEncounterSigned || isPatientReadonly(patientProfile)}
        />
      </div>
      <div>
        <CollapsibleText
          label="Vaccinations"
          textValue={state.vaccinationnotes}
          name="vaccinationnotes"
          rows={2}
          handleChange={handleFormInput}
          handleBlur={saveInfo}
          disabled={isEncounterSigned || isPatientReadonly(patientProfile)}
        />
      </div>
      <div>
        <CollapsibleText
          label="Medications"
          textValue={state.medicationnotes}
          name="medicationnotes"
          rows={2}
          handleChange={handleFormInput}
          handleBlur={saveInfo}
          disabled={isEncounterSigned || isPatientReadonly(patientProfile)}
        />
      </div>
      <div>
        <CollapsibleText
          label="Prechart notes"
          textValue={state.prechartnotes}
          name="prechartnotes"
          rows={2}
          handleChange={handleFormInput}
          handleBlur={saveInfo}
          disabled={isEncounterSigned || isPatientReadonly(patientProfile)}
        />
      </div>
    </div>
  );
};

export default Prechart;
