import React, { useMemo } from 'react';
import axios from '../../app/axiosConfig';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from '@mui/material';
import ButtonType from '../../components/Button';
import { useSearchPatientsQuery } from '../patients/queries/search-patients.query';
import { useSendEFaxMutation } from './mutations/send-efax.mutation';
import { useForm } from '@tanstack/react-form';
import { zodValidator } from '@tanstack/zod-form-adapter';
import { z } from 'zod';
import BasicTextfield from '../../components/Textfield';
import { PhoneNumberField } from '../../components/PhoneNumberField';
import useSearchQueryArgs from '../../hooks/useSearchQueryArgs';
import Combobox, { Option } from '../../components/Autocomplete';
import Typography from '../../components/Typography';
import { useDropzone } from 'react-dropzone';
import { cn } from '../../utils';
import { useSnackbar } from '../../components/Snack';
import {
  convertToBytes,
  getBestRepresentation,
} from '@aster/shared/utils/convert-to-bytes';
import { PatientActiveStatuses } from '@aster/shared/dtos/patient';
import Trash from '../../assets/icons/Trash';
import { SendFaxBody } from '@aster/shared/dtos/efax';
import { convertFileToBase64 } from './utils/fileUtils';
import { isAxiosError } from 'axios';

const houndredMB = convertToBytes(100, 'MB');

const acceptedFileTypes: Record<string, `.${string}`[]> = {
  // Document formats
  'application/msword': ['.doc'],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [
    '.docx',
  ],
  'text/plain': ['.txt', '.log', '.err'],

  // Spreadsheet formats
  'text/csv': ['.csv'],
  'application/vnd.ms-excel': ['.xls'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
    '.xlsx',
  ],
  'application/vnd.oasis.opendocument.spreadsheet': ['.ods'],

  // Image formats
  'image/jpeg': ['.jpg', '.jpeg'],
  'image/png': ['.png'],
  'image/gif': ['.gif'],
  'image/tiff': ['.tif', '.tiff'],
  'image/bmp': ['.bmp'],

  // Publishing formats
  'text/html': ['.htm', '.html'],
  'application/vnd.ms-publisher': ['.pub'],

  // Cross-platform formats
  'application/pdf': ['.pdf'],

  // Presentation formats
  'application/vnd.ms-powerpoint': ['.ppt'],
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': [
    '.pptx',
  ],
  'application/vnd.oasis.opendocument.presentation': ['.odp'],
};

interface ComposeFaxModalProps {
  open: boolean;
  onClose: () => void;
}

const ComposeFaxModal: React.FC<ComposeFaxModalProps> = ({ open, onClose }) => {
  const { showMessage, clearMessage } = useSnackbar();
  const handleClose = () => {
    form.reset();
    setSelectedFiles([]);
    onClose();
    search('');
  };

  const { sendEFaxMutation } = useSendEFaxMutation(handleClose);

  const form = useForm({
    defaultValues: {
      faxNumber: '',
      toName: '',
      subject: '',
      message: '',
      patientID: '',
    },
    validatorAdapter: zodValidator,
    onSubmit: async ({ value }) => {
      const documentsEncodingPromisies = selectedFiles.map(async (file) => {
        // NOTE: For the EFAX API the `file_type` needs to be specifically derived,
        // as they interpret it as kind of a «file extension» Passing a file's MIME
        // type (which follows the format of `<kind>/<type>`) doesn't work.
        //
        // As an example, a `.docx` file will be encoded as `application/msword` but
        // EFAX expects `DOC` to be passed as `file_type`. Coincidentally, the value
        // they expect is just the file extension in UPPERCASE and stripping the dot
        // out.
        //
        // Since we already have the supported MIME types, and their associated file
        // extensions, we can just derive the EFAX value by looking up by MIME type,
        // and grabbing the extension the file ends with. Otherwise, we default to a
        // '.txt' extension that gets interpreted as 'TXT', just like before.
        //
        // See: https://consensus.stoplight.io/docs/fax-services/7931576e60a00-send-a-fax#request-body
        const fileExtension =
          acceptedFileTypes[file.type].find((extension) =>
            file.name.endsWith(extension)
          ) ?? '.txt';

        const documentType = fileExtension.replace('.', '').toUpperCase();
        const documentContent = await convertFileToBase64(file);

        return {
          documentType,
          documentContent,
        };
      });

      const documents = await Promise.all(documentsEncodingPromisies);

      console.log(documents);

      const efaxBody: SendFaxBody = {
        faxNumber: value.faxNumber,
        toName: value.toName,
        subject: value.subject,
        message: value.message,
        patientID: value.patientID ? value.patientID : undefined,
        documents,
      };
      await sendEFaxMutation.mutateAsync(efaxBody);
    },
  });

  const [selectedFiles, setSelectedFiles] = React.useState<File[]>([]);
  const { searchQuery, search } = useSearchQueryArgs();
  const { paginatedPatients, arePatientsLoading, arePatientsFetching } =
    useSearchPatientsQuery({
      search: searchQuery,
      page: 0,
      pageSize: 25,
      statusFilter: PatientActiveStatuses,
    });
  const patientOptions = useMemo(() => {
    if (
      paginatedPatients?.items &&
      !arePatientsLoading &&
      !arePatientsFetching
    ) {
      return paginatedPatients.items.map((r) => ({
        id: r.id,
        label: `${r.firstName} ${r.lastName}`,
      }));
    }
    return [];
  }, [paginatedPatients?.items, arePatientsLoading, arePatientsFetching]);
  const unselectFile = (file: File) => {
    setSelectedFiles((prev) => prev.filter((f) => f !== file));
  };

  const assertFileRestrictions = (files: File[]): boolean => {
    clearMessage();
    if (files.length > 25) {
      showMessage({
        message: 'You can send up to 25 files at a time',
        type: 'error',
        autoClose: false,
      });
      return false;
    }
    const totalFileSize = selectedFiles.reduce(
      (acc, file) => acc + file.size,
      0
    );
    if (totalFileSize > houndredMB) {
      showMessage({
        message: 'Total file size cannot exceed 100MB',
        type: 'error',
        autoClose: false,
      });
      return false;
    }
    return true;
  };
  const { getRootProps, getInputProps } = useDropzone({
    accept: acceptedFileTypes,
    onDrop: (files) => {
      if (assertFileRestrictions(files)) {
        setSelectedFiles(files as File[]);
      }
    },
  });

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Typography variant="h4">Compose a fax</Typography>
      </DialogTitle>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          void form.handleSubmit();
        }}
      >
        <DialogContent>
          <Typography variant="h6">Choose a recipient</Typography>
          <div className="flex flex-col space-y-5 mt-2">
            <div className="bg-grayCard rounded-lg p-5 space-y-4 mt-4">
              <form.Field
                name="faxNumber"
                validators={{
                  onChange: z.string().length(17, {
                    message: 'Please provide a valid US phone number',
                  }),
                  onChangeAsyncDebounceMs: 300,
                  onChangeAsync: async ({ value }) => {
                    if (!value) {
                      return undefined;
                    }
                    try {
                      await axios.post('/practices/validate-phone-number', {
                        phoneNumber: value,
                      });
                    } catch (err) {
                      if (isAxiosError(err)) {
                        return 'Please provide an active US phone number';
                      } else {
                        throw err;
                      }
                    }
                  },
                }}
                children={(field) => (
                  <PhoneNumberField
                    id={field.name}
                    variant="filled"
                    label="Phone"
                    name={field.name}
                    value={field.state.value}
                    onBlur={field.handleBlur}
                    placeholder="(xxx) xxx-xxxx"
                    error={field.state.meta.errors?.length > 0}
                    helperText={field.state.meta.errors?.join('\r')}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      field.handleChange(event.target.value)
                    }
                    width="100%"
                  />
                )}
              />
              <form.Field
                name="toName"
                children={(field) => (
                  <BasicTextfield
                    variant="filled"
                    label="Attention Of"
                    onBlur={field.handleBlur}
                    onChange={(evt: any) =>
                      field.handleChange(evt.target.value)
                    }
                    value={field.state.value}
                    error={field.state.meta.errors.length > 0}
                    helperText={field.state.meta.errors.join('\r')}
                  />
                )}
              />
              <form.Field
                name="subject"
                children={(field) => (
                  <BasicTextfield
                    variant="filled"
                    label="Subject"
                    onBlur={field.handleBlur}
                    onChange={(evt: any) =>
                      field.handleChange(evt.target.value)
                    }
                    value={field.state.value}
                    error={field.state.meta.errors.length > 0}
                    helperText={field.state.meta.errors.join('\r')}
                  />
                )}
              />
              <form.Field
                name="message"
                children={(field) => (
                  <BasicTextfield
                    variant="filled"
                    label="Message"
                    onBlur={field.handleBlur}
                    onChange={(evt: any) =>
                      field.handleChange(evt.target.value)
                    }
                    value={field.state.value}
                    error={field.state.meta.errors.length > 0}
                    helperText={field.state.meta.errors.join('\r')}
                    multiline
                  />
                )}
              />

              <form.Field
                name="patientID"
                children={(field) => (
                  <Combobox
                    // freeSolo silences gigantic console warning
                    freeSolo
                    className="mb-4"
                    label="Patient"
                    isServerSide={true}
                    loading={arePatientsLoading || arePatientsFetching}
                    onChange={(_, newValue) => {
                      field.handleChange((newValue as Option)?.id);
                    }}
                    onInputChange={(_: any, newInputValue: string) => {
                      search(newInputValue);
                    }}
                    options={patientOptions}
                    width={'100%'}
                  />
                )}
              />
            </div>
            <Typography variant="h6">Attach files</Typography>
            <div>
              <Typography variant="bodySmall">
                Please keep the total file size under 100MB.
              </Typography>
              <Typography variant="bodySmall" customClass="mt-0">
                A maximum of 25 files can be attached.
              </Typography>
            </div>
            <div
              {...getRootProps({
                className: cn(
                  'relative p-6 w-auto cursor-pointer mx-auto bg-grayCard content-center text-center overflow-hidden h-[90px] rounded-[8px] w-full hover:bg-grayBlock'
                ),
              })}
            >
              <input {...getInputProps()} />
              <Typography variant="bodyMedium">
                Drag and drop a file, or{' '}
                <span className="underline">Browse</span>
              </Typography>
            </div>
            <Thumbnails
              selectedFiles={selectedFiles}
              unselectFile={unselectFile}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <form.Subscribe
            selector={(state) => [
              state.canSubmit,
              state.isSubmitting,
              state.isFieldsValidating,
              state.isValid,
            ]}
            children={([canSubmit, isSubmitting, isValidating]) => (
              <>
                <ButtonType
                  variant="text"
                  type="button"
                  onClick={handleClose}
                  text="Cancel"
                  className="min-w-20 cursor-pointer"
                />
                <ButtonType
                  variant="contained"
                  disabled={!canSubmit || selectedFiles.length === 0}
                  loading={isSubmitting || isValidating}
                  type="submit"
                  text="Send"
                  className="min-w-20"
                />
              </>
            )}
          />
        </DialogActions>
      </form>
    </Dialog>
  );
};

const Thumbnails = ({
  selectedFiles,
  unselectFile,
}: {
  selectedFiles: File[];
  unselectFile: (file: File) => void;
}) =>
  selectedFiles &&
  selectedFiles.map((file) => (
    <div className="br-2 ml-8 mr-8" key={file?.name}>
      <div className="flex items-center gap-2">
        <IconButton className="p-2" onClick={() => unselectFile(file)}>
          <Trash />
        </IconButton>
        <Typography variant="bodyMedium" text={file?.name} />
        <Typography
          customClass="ml-auto font-semibold"
          variant="bodyMedium"
          text={
            getBestRepresentation(file?.size).value.toFixed() +
            getBestRepresentation(file?.size).unit
          }
        />
      </div>
    </div>
  ));

export default ComposeFaxModal;
