import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  IconButton,
  Divider,
  CircularProgress,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { useEffect, useState } from 'react';
import { downloadFile } from '../documents/utils/download-file';
import { useAuth } from '../../authentication/AuthProvider';
import { FaxListItemDTO } from '@aster/shared/dtos/efax';
import { createStorageKey } from '@aster/shared/utils/storage';

interface FaxDetailModalProps {
  open: boolean;
  onClose: () => void;
  fax: FaxListItemDTO;
}

const FaxDetailModal: React.FC<FaxDetailModalProps> = ({
  open,
  onClose,
  fax,
}) => {
  const [pdfFileUrl, setPdfFileUrl] = useState<string>();
  const { profile } = useAuth();

  useEffect(() => {
    if (open) {
      downloadFile(
        createStorageKey({
          practiceId: profile?.practiceId as string,
          scope: 'efax',
          name: `${fax.id}.pdf`,
        }),
        `${fax.id}.pdf`,
        (url) => setPdfFileUrl(url),
        false
      );
    } else {
      setPdfFileUrl(undefined);
    }
  }, [fax.id, open, profile?.practiceId]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      classes={{ paper: 'h-full' }}
    >
      <div className="flex justify-between items-center p-4">
        <DialogTitle>View Fax</DialogTitle>
        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <Divider />
      <DialogContent className="grid grid-cols-fill-last overflow-hidden">
        <div className="flex flex-col p-4 space-y-2">
          {fax.from && (
            <Typography variant="body2">
              <strong>From:</strong> {fax.from || '-'}
            </Typography>
          )}
          {fax.to && (
            <Typography variant="body2">
              <strong>To:</strong> {fax.to || '-'}
            </Typography>
          )}
          {fax.patientName && (
            <Typography variant="body2">
              <strong>Patient Name:</strong> {fax.patientName || '-'}
            </Typography>
          )}
          {fax.sentByName && (
            <Typography variant="body2">
              <strong>Sent By:</strong> {fax.sentByName || '-'}
            </Typography>
          )}
        </div>
        <div className="p-4 bg-gray-300 flex justify-center items-center overflow-auto">
          {!pdfFileUrl || !profile ? (
            <CircularProgress />
          ) : (
            <Worker
              workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}
            >
              {pdfFileUrl && <Viewer fileUrl={pdfFileUrl} />}
            </Worker>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default FaxDetailModal;
