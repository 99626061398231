import Typography from '../components/Typography';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import ArrowRightRoundedIcon from '@mui/icons-material/ArrowRightRounded';
import { useState } from 'react';
import { customTwMerge as twMerge } from '../utils';

type CollapsibleTextProps = {
  label: string;
  textValue: string;
  name: string;
  rows?: number;
  width?: string;
  disabled?: boolean;
  handleChange?: any;
  handleBlur?: any;
  extraComponent?: any;
  hideText?: boolean;
  children?: React.ReactNode;
};

const CollapsibleText = ({
  label,
  textValue,
  name,
  rows,
  disabled,
  handleChange,
  handleBlur,
  hideText,
  children,
}: CollapsibleTextProps) => {
  const [isCollapsed, setCollapsed] = useState(true);

  const toggleCollapse = () => {
    setCollapsed(!isCollapsed);
  };

  return (
    <div className="w-full h-full">
      <div className="w-full">
        <button
          onClick={toggleCollapse}
          className={twMerge(
            'w-full rounded-t-lg flex justify-start items-center bg-grayCard',
            !isCollapsed
              ? 'rounded-lg bg-white hover:bg-grayBackground pr-5'
              : 'rounded-t-lg'
          )}
        >
          {!isCollapsed && <ArrowRightRoundedIcon />}
          {isCollapsed && <ArrowDropDownRoundedIcon />}
          <Typography variant="h6" text={label} />
        </button>
      </div>

      {isCollapsed && (
        <div className="bg-grayCard py-1 rounded-b-lg px-3">
          {children}
          {!hideText && (
            <textarea
              rows={rows ? rows : 2}
              cols={50}
              name={name}
              placeholder="Insert notes..."
              value={textValue}
              disabled={disabled}
              onChange={handleChange}
              onBlur={handleBlur}
              className="rounded-[10px] border border-grayLight focus:border-gray focus:outline-none p-2 w-full"
            ></textarea>
          )}
        </div>
      )}
    </div>
  );
};

export default CollapsibleText;
