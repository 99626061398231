import { downloadUrl } from './fileUtils';
import { MouseEvent } from 'react';
import Link from '@mui/material/Link';
import { useAuth } from '../../../authentication/AuthProvider';
import { getFileURL } from '../../documents/utils/download-file';
import { createStorageKey } from '@aster/shared/utils/storage';

interface DownloadPdfButtonProps {
  faxId: string;
  disabled?: boolean;
}

export const DownloadPdfButton = ({
  faxId,
  disabled = false,
}: DownloadPdfButtonProps) => {
  const { profile } = useAuth();
  const downloadPdf = async (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    const url = await getFileURL(
      createStorageKey({
        practiceId: profile?.practiceId as string,
        scope: 'efax',
        name: `${faxId}.pdf`,
      }),
      `${faxId}.pdf`
    );

    if (url) {
      downloadUrl(url, faxId);
    }
  };

  return (
    <Link
      component="button"
      onClick={downloadPdf}
      disabled={disabled}
      className="disabled:no-underline disabled:text-gray-500"
    >
      EFax PDF
    </Link>
  );
};
