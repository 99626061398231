import { PhoneNumberField } from '../../../components/PhoneNumberField';
import BasicTextfield from '../../../components/Textfield';
import Typography from '../../../components/Typography';
import { ContactInfo } from '../types/patient-profile';

type ContactInfoProps = {
  contactInfo: ContactInfo;
  handleInfo: (event: any) => void;
  readonly: boolean;
};

const ContactInformation = (props: ContactInfoProps) => {
  const {
    phoneNumber,
    email,
    streetAddress1,
    streetAddress2,
    city,
    state,
    zipCode,
  } = props.contactInfo;
  const handleInfo = props.handleInfo;

  return (
    <div className="h-fit flex flex-wrap justify-center md:justify-start gap-10 xl:gap-40">
      <div>
        <Typography
          text="Contact Information"
          variant="h5"
          customClass="font-semibold mb-5"
        ></Typography>
        <PhoneNumberField
          classes="mb-5"
          id="phoneNumber"
          variant="filled"
          label="Phone"
          placeholder="(xxx) xxx-xxxx"
          name="phoneNumber"
          value={phoneNumber}
          onChange={handleInfo}
          required
          disabled={props.readonly}
        />
        <BasicTextfield
          id="email"
          variant="filled"
          label="Email"
          name="email"
          value={email}
          onChange={handleInfo}
          classes={`mt-4 w-[300px]`}
          required
          disabled={props.readonly}
        />
      </div>
      <div className="mt-4 md:mt-0">
        <Typography
          text="Address"
          variant="h5"
          customClass="font-semibold mb-5"
        ></Typography>
        <BasicTextfield
          classes="mb-5 w-[300px]"
          id="streetAddress1"
          variant="filled"
          label="Address Line 1"
          name="streetAddress1"
          value={streetAddress1}
          onChange={handleInfo}
          required
          disabled={props.readonly}
        />
        <BasicTextfield
          classes="mb-5 w-[300px]"
          id="streetAddress1"
          variant="filled"
          label="Address Line 2"
          name="streetAddress2"
          value={streetAddress2}
          onChange={handleInfo}
          disabled={props.readonly}
        />
        <BasicTextfield
          classes="mb-5 w-[300px]"
          id="city"
          variant="filled"
          label="City"
          name="city"
          value={city}
          onChange={handleInfo}
          disabled={props.readonly}
        />
        <BasicTextfield
          classes="mb-5 w-[300px]"
          id="state"
          variant="filled"
          label="State"
          name="state"
          value={state}
          onChange={handleInfo}
          disabled={props.readonly}
        />
        <BasicTextfield
          classes="mb-5 w-[300px]"
          id="zipcode"
          variant="filled"
          label="Zip Code"
          name="zipCode"
          value={zipCode}
          onChange={handleInfo}
          disabled={props.readonly}
        />
      </div>
    </div>
  );
};

export default ContactInformation;
