import { Payments } from '../features/payments/Payments';

const PaymentsPage = () => {
  return (
    <div className="container h-screen overflow-auto">
      <Payments />
    </div>
  );
};

export default PaymentsPage;
