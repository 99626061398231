export const colors = {
  aster: '#203A3B',
  asterGray: '#586068',
  beige: '#F1ECE9',
  black: '#000000',
  blue: '#0588D1',
  dataGridGray: '#F2F4F5',
  disabledGray: '#0000001F',
  gray: '#586068',
  green: '#09BC8A',
  grayBackground: '#F2F5F4',
  grayCard: '#F7F8F8',
  grayBlock: '#D3D3D3',
  grayLight: '#CDD1DE',
  'aster-neutral': '#1F2937',
  pastelBlue: '#A0C4FF',
  pastelCyan: '#9BF6FF',
  pastelGreen: '#CAFFBF',
  pastelPink: '#FFC6FF',
  pastelPurple: '#BDB2FF',
  pastelOrange: '#FFD6A5',
  pastelRed: '#FFADAD',
  pastelYellow: '#FDFFB6',
  primary: '#222222',
  purple: '#BDB2FF',
  red: '#FB3640',
  orange: '#FCD34D',
  secondary: '#DB9D47',
  warning: '#F59E0B',
  transparent: 'transparent',
  white: '#FFFFFF',
};
