import { S3Client } from '@aws-sdk/client-s3';
import { fetchAuthSession } from 'aws-amplify/auth';

export const getS3ClientWithCredentials = async () => {
  const { credentials } = (await fetchAuthSession()) ?? false;
  if (credentials) {
    const { accessKeyId, secretAccessKey, sessionToken } = credentials;
    return new S3Client({
      region: 'us-east-1',
      credentials: {
        accessKeyId,
        secretAccessKey,
        sessionToken,
      },
    });
  }
  return false;
};
