import React from 'react';
import analytics from '../assets/empty-images/analytics.webp';

const Analytics = () => {
  return (
    <div className="flex flex-col justify-center items-center h-full w-full">
      <img src={analytics} alt="Analytics" width="481px" />
      <div className="flex flex-col items-center">
        <p className="text-h3 text-primary">Analytics will be available soon</p>
        <p className="text-body text-gray-500">
          Generate reports and datasets for CABC, PDR, and more.
        </p>
      </div>
    </div>
  );
};

export default Analytics;
